import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NagercoilService } from '../_services/nagercoil.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-movie-dailog',
  templateUrl: './movie-dailog.component.html',
  styleUrls: ['./movie-dailog.component.css']
})

export class MovieDailogComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data, private fb: FormBuilder, private router: Router, private Service: NagercoilService, public dialogRef: MatDialogRef<MovieDailogComponent>, private toastr: ToastrService) { }

	public user: object;
	public movieTicketForm: FormGroup;
	public errorMsg : boolean = false;
	public loader: boolean = false;
	public mode: string = "Terms"
	public confirmMsg: boolean = false;
	public isSuccess: boolean = false;
	public totalPrice: any;
	public maxTickets: number;
	public isBookingTimeExceed:boolean = false;

	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.maxTickets = 10;
		// if(this.data.showDetails.AvailableSeats) {
		// 	this.maxTickets = this.data.showDetails.AvailableSeats >= 10 ? 10 : this.data.showDetails.AvailableSeats
		// }
		
		this.movieTicketForm = this.fb.group({
			seats: [null, Validators.compose([Validators.required])],
			comments: [null,''],
			description: [null,''],
		});
		if(!this.data.theatreDetails.termsCondition) {
			this.mode = 'Progress'
		}
		this.formControlValueChanged()
	}
	  
	counter(i: number) {
		return new Array(i);
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}
	
	onAccept(): void {
		this.mode = "Progress";
		this.dialogRef.updateSize("500px", "auto");
	}

	confirmTicket() {

		if (this.movieTicketForm.invalid) {
			return;
		}

		this.loader = true;
		let route = "v2/product";
		let token = this.user['token'];
		var params = {};
		var items = {
			'threatreshowid': this.data.theatreDetails.TheatreMovieShowId,
			'tickets' : parseInt(this.movieTicketForm.value.seats),
			'time': this.data.showDetails.time,
			'booking_date': this.data.showDetails.date
		}
		params['items'] = items;

		params['category'] = 4;

		if(this.movieTicketForm.value.description) {
			params['description'] =  this.movieTicketForm.value.description.trim();
		}

		if(this.movieTicketForm.value.comments) {
			params['comments'] =  this.movieTicketForm.value.comments.trim();
		}
		this.Service.post(route, token, params).then(response=> {
			this.mode = "Result";
			this.dialogRef.updateSize("500px", "auto");
			if(response.status) {
				this.confirmMsg = true;
				this.isSuccess = true;
			} else if(response.message == "Booking time exceeded") {
				this.confirmMsg = false;
				this.isBookingTimeExceed = true;
			} else {
				this.confirmMsg = false;
				this.isBookingTimeExceed = false;
			}
		});
	}

	afterConfirm() {
		if(this.isSuccess) {
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	} 
	
	formControlValueChanged() {
		this.movieTicketForm.controls['seats'].valueChanges.subscribe(checked => {
			if(checked) {
				this.totalPrice =  checked * this.data.theatreDetails.TicketPrice;
			}
		})
	}


}
