import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { NagercoilService } from '../_services/nagercoil.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
	'url': string;
}

@Component({
  selector: 'app-youtube-dialog',
  templateUrl: './youtube-dialog.component.html',
  styleUrls: ['./youtube-dialog.component.css']
})

export class YoutubeDialogComponent implements OnInit {

	public user: object;
	public errorMsg : boolean = false;
	public loader: boolean = false;
	public player: YT.Player;
	private id: string = 'qDuKsiwS5xw';
	
	constructor(private toastr: ToastrService, public dialogRef: MatDialogRef<YoutubeDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData, private Service: NagercoilService) { }
	
	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
	}

	savePlayer(player: any) {
		this.player = player;
		this.player.playVideo()
	}

	onStateChange(event: any) {
	}
	
  
}
