import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { Router } from '@angular/router';
import { NagercoilService } from "../_services/nagercoil.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { YoutubeDialogComponent } from '../youtube-dialog/youtube-dialog.component';
import { MatDialog } from '@angular/material';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-movie',
	templateUrl: './movie.component.html',
	styleUrls: ['./movie.component.css'],
	animations: [
		trigger('fade', [
			transition('void => *', [style({ opacity: 0.5 }), animate('300ms', style({ opacity: 1 }))]),
			transition('* => void', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0.5 }))]),
		])
	]
})

export class MovieComponent implements OnInit {
	public movieSearchForm: FormGroup;
	public comingSoon: Object[];
	public nowShowing: Object[];
	public movieData: Object[];
	public constMovieData: Object[];
	public user: object;
	public bannerImages = [];
	public PageLoader: boolean = false;
	public Genre: Object[];
	public language: Object[];
	public imagesUrl;
	public tabIndex = 0;
	public aryDates: any;
	public selectedDate: any;
	public startMoment: any;
	public endMoment: any;
	public theatreShowTime: any;
	public currentDate: any;
	public player: YT.Player;
	public currentMoment: any;
	public istMoment = moment();
	public IstTimeZone = "Asia/Kolkata";
	public timeFormat = "HH:mm";
	public carouselOptions = {
		autoplay: true,
		dots: false,
		autoplayTimeout: 3500,
		loop: true,
		autoplayHoverPause: true,
		margin: 25,
		center: true,
		nav: true,
		navText: ["<div class='nav-btn prev-slide'> <i class='material-icons'>	keyboard_arrow_left </i> </div>", "<div class='nav-btn next-slide'><i class='material-icons'>	keyboard_arrow_right </i></div>"],
		responsiveClass: true,
		responsive: {
			0: {
				items: 1,
				loop: true,
				nav: true
			},
			600: {
				items: 1,
				loop: true,
				nav: true
			},
			1000: {
				items: 2,
				loop: true
			},
			1500: {
				items: 3,
				loop: true
			}
		}
	}

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
		private router: Router, private Service: NagercoilService, private fb: FormBuilder, public dialog: MatDialog) {
		this.movieSearchForm = this.fb.group({
			'moviename': ['']
		});
		this.formControlValueChanged();
	}

	ngOnInit() {
		this.currentMoment = moment.tz(this.IstTimeZone);
		this.PageLoader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (!this.user) {
			this.router.navigate(['signin']);
		} else {
			// this.currentDate = new Date();
			// this.startEpoch = this.currentDate.setHours(0, 0, 0, 0);
			// this.endEpoch = this.currentDate.setHours(23, 59, 59, 999);
			this.startMoment = moment.tz(this.IstTimeZone).startOf("day");
			this.endMoment = moment.tz(this.IstTimeZone).endOf("day");
			this.getMovies();
		}
	}

	getMovies() {
		let route = 'movies';
		this.Service.get(route, this.user['token']).then(response => {
			this.PageLoader = false;
			this.renderer.removeClass(this.document.body, 'bdovflw_hi');
			if (response.data) {
				this.movieData = response.data;
				this.constMovieData = JSON.parse(JSON.stringify(this.movieData))
				this.Genre = [];
				this.language = [];
				var self = this;
				this.movieAggregation(function (response) {
					for (let i = 0; i < self.movieData.length; i++) {
						self.movieData[i]['ShowDetails'] = [];
						for (let j = 0; j < response.length; j++) {
							if (self.movieData[i]['Id'] == response[j]['movieId']) {
								self.movieData[i]['ShowDetails'].push(response[j]);
							}
						}
					}
				})
				this.comingSoon = this.movieData.filter(function (product: any) {
					return product['Type'] == 'up_coming'
				});
				this.nowShowing = this.movieData.filter(function (product: any) {
					return product['Type'] == "now_showing"
				});
				for (let i = 0; i < this.movieData.length; i++) {
					let GenreIndex = this.Genre.map(function (x) {
						return x['name'];
					}).indexOf(this.movieData[i]['Genre'])
					if (GenreIndex == -1) {
						this.Genre.push({
							'name': this.movieData[i]['Genre'],
							'checked': false
						})
					}

					let langIndex = this.language.map(function (x) {
						return x['name'];
					}).indexOf(this.movieData[i]['Language'])
					if (langIndex == -1) {
						this.language.push({
							'name': this.movieData[i]['Language'],
							'checked': false
						})
					}

					if (this.movieData[i]['Images'] && this.movieData[i]['Images'].length) {
						for (let j = 0; j < this.movieData[i]['Images'].length; j++) {
							if (this.movieData[i]['Images'][j]['ImageType'] == "banner") {
								this.bannerImages.push({
									'Image': this.movieData[i]['Images'][j]['ImageUrl'],
									'Id': this.movieData[i]['Id']
								})
							}
						}
					}
				}
			}
		});
	}

	langCheck(index: any, $event: any) {
		this.language[index]['checked'] = $event.checked;
		this.filter();
	}

	GenreCheck(index: any, $event: any) {
		this.Genre[index]['checked'] = $event.checked;
		this.filter();
	}

	filter() {
		let route = 'movies';
		this.Service.get(route, this.user['token']).then(response => {
			if (response.data) {
				this.movieData = response.data;
				var self = this;
				this.movieAggregation(function (response) {
					for (let i = 0; i < self.movieData.length; i++) {
						self.movieData[i]['ShowDetails'] = [];
						for (let j = 0; j < response.length; j++) {
							if (self.movieData[i]['Id'] == response[j]['movieId']) {
								self.movieData[i]['ShowDetails'].push(response[j]);
							}
						}
					}
				})
				var checkedLanguage = this.language.filter(function (el) {
					return (el['checked'] === true);
				}).map(function (el) {
					return el['name'];
				})
				var checkedGenre = this.Genre.filter(function (el) {
					return (el['checked'] === true);
				}).map(function (el) {
					return el['name'];
				})


				if (checkedLanguage && checkedLanguage.length) {
					this.movieData = this.movieData.filter(function (el) {
						return checkedLanguage.includes(el['Language'])
					})
				}

				if (checkedGenre && checkedGenre.length) {
					this.movieData = this.movieData.filter(function (el) {
						return checkedGenre.includes(el['Genre'])
					})
				}

				if (this.movieSearchForm.value.moviename) {
					var self = this
					this.movieData = this.movieData.filter(function (el) {
						return el['Name'].toLowerCase().includes(self.movieSearchForm.value.moviename.toLowerCase())
					})
				}

				this.comingSoon = this.movieData.filter(function (product: any) {
					return product['Type'] == 'up_coming'
				});

				this.nowShowing = this.movieData.filter(function (product: any) {
					return product['Type'] == "now_showing"
				});

				if ((this.tabIndex == 0) && (this.nowShowing && this.nowShowing.length) && (this.comingSoon && this.comingSoon.length)) {
					this.tabIndex = 0;
				} else if ((this.tabIndex == 1) && (this.nowShowing && this.nowShowing.length) && (this.comingSoon && this.comingSoon.length)) {
					this.tabIndex = 1;
				} else if ((this.tabIndex == 0) && (this.nowShowing && !this.nowShowing.length) && (this.comingSoon && this.comingSoon.length)) {
					this.tabIndex = 1;
				} else if ((this.tabIndex == 0) && (this.nowShowing && this.nowShowing.length) && (this.comingSoon && !this.comingSoon.length)) {
					this.tabIndex = 0;
				} else if ((this.tabIndex == 1) && (this.nowShowing && this.nowShowing.length) && (this.comingSoon && !this.comingSoon.length)) {
					this.tabIndex = 0;
				} else if ((this.tabIndex == 1) && (this.nowShowing && !this.nowShowing.length) && (this.comingSoon && this.comingSoon.length)) {
					this.tabIndex = 1;
				} else {
					this.tabIndex = 0;
				}

			}
		});
	}
	getMovieCardImage(movieImgArray) {
		if (movieImgArray && movieImgArray.length) {
			let Index = movieImgArray.findIndex(x => x['ImageType'] === 'card')
			if (Index != -1) {
				return movieImgArray[Index]['ImageUrl']
			}
		} else {
			return "../../assets/images/icons/movie_icon/movie_small_card.jpg";
		}

	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons/movie_icon/movie_small_card.jpg";
	}

	bannerImageErrorHandler(event) {
		event.target.src = "../../assets/images/no_movie_banner.jpg";
	}

	formControlValueChanged() {
		this.movieSearchForm.controls['moviename'].valueChanges.pipe
			(debounceTime(800))
			.subscribe(checked => {
				this.filter()
			});
	}

	movieAggregation(callback: any) {
		this.theatreShowTime = []
		let iscurrentDate = false;
		for (let i = 0; i < this.movieData.length; i++) {
			for (let j = 0; j < this.movieData[i]["TheatresList"].length; j++) {
				// if ((this.startEpoch >= (this.movieData[i]["TheatresList"][j].From * 1000)) && (this.endEpoch <= new Date(this.movieData[i]["TheatresList"][j].To * 1000).setHours(23, 59, 59, 999))) {
				let movieStartsOn = moment.tz((this.movieData[i]["TheatresList"][j].From * 1000), 'Asia/Kolkata');
				let movieEndsOn = moment.tz((this.movieData[i]["TheatresList"][j].To * 1000), 'Asia/Kolkata').endOf("day");
				if (this.startMoment.diff(movieStartsOn) >= 0 && this.endMoment.diff(movieEndsOn) <= 0) {
					iscurrentDate = true;
					let Index = this.theatreShowTime.findIndex(x => (x['theatreName'] === this.movieData[i]["TheatresList"][j].TheatreName) && x['movieId'] == this.movieData[i]['Id'])
					if (Index == -1) {
						this.theatreShowTime.push({
							'movieName': this.movieData[i]['Name'],
							'genre': this.movieData[i]['Genre'],
							'movieId': this.movieData[i]['Id'],
							'language': this.movieData[i]['Language'],
							'theatreName': this.movieData[i]['TheatresList'][j].TheatreName,
							'showTime': this.movieData[i]['TheatresList'][j].Time,
							'ticketPrice': this.movieData[i]['TheatresList'][j].TicketPrice,
							'termsCondition': this.movieData[i]['TermsAndConditions'] ? this.movieData[i]['TermsAndConditions'] : ''
						})
					} else {
						this.theatreShowTime[Index].showTime.push(this.movieData[i]["TheatresList"][j])
					}
				}
			}

		}
		callback(this.theatreShowTime);
	}
	openPlayer(movieObj) {
		var video_id = movieObj.Trailers[0].TrailerUrl.split('v=')[1];
		var ampersandPosition = video_id.indexOf('&');
		if (ampersandPosition != -1) {
			video_id = video_id.substring(0, ampersandPosition);
		}
		var data = {
			'url': video_id
		}
		const dialogRef = this.dialog.open(YoutubeDialogComponent, {
			panelClass: 'myapp-no-padding-dialog',
			data: data

		});
		dialogRef.afterClosed().subscribe(result => {
		});
	}

	savePlayer(player) {
		this.player = player;
	}

	onStateChange(event) {
	}
}