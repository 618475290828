import { Component, OnInit } from '@angular/core';
import { NagercoilService } from "../_services/nagercoil.service";
import { FormGroup, FormBuilder,Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public form: FormGroup;
  public user: object;
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder,private router: Router, private Service: NagercoilService) {
    this.form = fb.group({
			'name': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'email': ['', Validators.compose([Validators.required, Validators.email])],
			'mobile': ['', Validators.compose([Validators.required])],
			'subject':['', Validators.compose([Validators.required])],
			'message': ['', Validators.compose([Validators.required])],
		});
   }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userCredential'));
  } 
  onSubmit(formDirective) {
    let route = 'reach/us';
    let token = "";
    let params = {
      name : this.form.value.name,
      email : this.form.value.email,
      mobile : this.form.value.mobile,
      subject : this.form.value.subject,
      message : this.form.value.message
  };
  this.Service.post(route, token, params).then(response => { 
    if(response.success) {
      this.toastr.success('We will get back to you Shortly', 'Your message has received successfully,');
      formDirective.resetForm();
      this.form.reset();
    } else {
      if(response.messages.mobile) {
          this.toastr.error(response.messages.mobile, 'Error!');
      } else if(response.messages.message) {
          this.toastr.error(response.messages.message, 'Error!');
      } else if(response.messages.name) {
          this.toastr.error(response.messages.name, 'Error!');
      } else if(response.messages.subject) {
          this.toastr.error(response.messages.subject, 'Error!');
      } else {
          this.toastr.error('Please try after sometime.', 'Something went wrong!');
      }
    }
  });
  }

}
