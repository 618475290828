import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NagercoilService} from '../_services/nagercoil.service';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { YoutubeDialogComponent } from '../youtube-dialog/youtube-dialog.component';
import { MovieDailogComponent } from '../movie-dailog/movie-dailog.component';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-movie-details',
  templateUrl: './movie-details.component.html',
  styleUrls: ['./movie-details.component.css'],
  providers: [DatePipe],
})

export class MovieDetailsComponent implements OnInit {
	public seats = [
		{ value: 1, viewValue: '1' },
		{ value: 2, viewValue: '2' },
		{ value: 3, viewValue: '3' },
		{ value: 4, viewValue: '4' },
		{ value: 5, viewValue: '5' },
		{ value: 6, viewValue: '6' },
		{ value: 7, viewValue: '7' },
		{ value: 8, viewValue: '8' },
		{ value: 9, viewValue: '9' }
	];
  
    public user: object;
    public id: any;
    public movieData: any;
    public yt_iframe_html: any;
	public player: YT.Player;
	public theatreShowTime : any;
	public aryDates: any;
	public selectedDate : any;
	public PageLoader: boolean = false;
	public startEpoch: any;
	public endEpoch: any;
	public panelClass: string;
	public movieBookingDate: any;
	public currentMoment: any;
	public istMoment = moment();
	public IstTimeZone = "Asia/Kolkata";
	public timeFormat = "HH:mm";

	constructor(@Inject(DOCUMENT) private document: Document,private renderer: Renderer2,
	public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private Service: NagercoilService,private datePipe: DatePipe) {}

  	ngOnInit() { 
		this.PageLoader = true;
		this.renderer.removeClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(!this.user) {
			this.router.navigate(['signin']);
		} else {
			// var startDate = new Date();
			this.currentMoment = moment.tz(this.IstTimeZone);
			this.aryDates = this.GetDates();
			this.aryDates[0].isSelected = true;
			this.startEpoch = this.aryDates[0].startEpoch
			this.endEpoch = this.aryDates[0].endEpoch
			this.selectedDate = this.aryDates
			this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id: null
			if(this.id) {
				let route = 'movies/' + this.id;
				this.Service.get(route, this.user['token']).then(response => {
					this.PageLoader = false;
					this.renderer.removeClass(this.document.body, 'bdovflw_hi');
					if(response.data && response.data.length) {
						this.movieData = response.data[0];
						this.movieAggregation()
					} 
					if(!response.data.length) {
						this.router.navigate(['**']);
					}
				});
			}
		}
	}

	getMovieCardImage(movieImgArray) {
		let Index = movieImgArray.findIndex(x => x['ImageType'] === 'card')
		if(Index != -1){
			return movieImgArray[Index]['ImageUrl']
		}
	}
	getMovieBannerImage(movieImgArray){
		let Index = movieImgArray.findIndex(x => x['ImageType'] === 'banner')
		if(Index != -1){
			return movieImgArray[Index]['ImageUrl']
		}
	}

	// movieAggregation() {
	// 	this.theatreShowTime = []
	// 	for(let i=0; i<this.movieData.TheatresList.length; i++) {
	// 		if(((this.movieData.TheatresList[i].DateTime * 1000) >= this.startEpoch) && ((this.movieData.TheatresList[i].DateTime * 1000) <= this.endEpoch)) {
	// 			let Index = this.theatreShowTime.map(function(x) {
	// 				return x['theatreName'];
	// 			}).indexOf(this.movieData.TheatresList[i].TheatreName)
	// 			if(Index == -1) {
	// 				this.theatreShowTime.push({
	// 					'movieName' : this.movieData.Name,
	// 					'genre' : this.movieData.Genre,
	// 					'movieId' : this.movieData.Id,
	// 					'language' : this.movieData.Language,
	// 					'theatreName': this.movieData.TheatresList[i].TheatreName,
	// 					'showTime': [this.movieData.TheatresList[i]],
	// 					'ticketPrice' : this.movieData.TheatresList[i].TicketPrice,
	// 					'termsCondition':this.movieData.TermsAndConditions ? this.movieData.TermsAndConditions: ''
	// 				})
	// 			} else {
	// 				this.theatreShowTime[Index].showTime.push(this.movieData.TheatresList[i])
	// 			}
	// 		}
			
	// 	}
	// }

	movieAggregation() {
		this.theatreShowTime = []
		for(let i=0; i<this.movieData.TheatresList.length; i++) {
			// if(this.startEpoch >= ((this.movieData.TheatresList[i].From * 1000)) && (this.endEpoch <= new Date(this.movieData.TheatresList[i].To * 1000).setHours(23,59,59,999))) {
				let movieStartsOn = moment.tz((this.movieData.TheatresList[i].From * 1000), 'Asia/Kolkata');
				let movieEndsOn = moment.tz((this.movieData.TheatresList[i].To * 1000), 'Asia/Kolkata').endOf("day");	
			if(this.startEpoch.diff(movieStartsOn) >= 0 && this.endEpoch.diff(movieEndsOn) <= 0) {
				let Index = this.theatreShowTime.map(function(x) {
					return x['theatreName'];
				}).indexOf(this.movieData.TheatresList[i].TheatreName)
				if(Index == -1) {
					this.theatreShowTime.push({
						'movieName' : this.movieData.Name,
						'genre' : this.movieData.Genre,
						'movieId' : this.movieData.Id,
						'language' : this.movieData.Language,
						'TheatreMovieShowId': this.movieData.TheatresList[i].TheatreMovieShowId,
						'TheatreName': this.movieData.TheatresList[i].TheatreName,
						'showTime': this.movieData.TheatresList[i].Time,
						'TicketPrice' : this.movieData.TheatresList[i].TicketPrice,
						'termsCondition':this.movieData.TermsAndConditions ? this.movieData.TermsAndConditions: ''
					})
				} else {
					this.theatreShowTime[Index].showTime.push(this.movieData.TheatresList[i])
				}
			}
		}
	}


	GetDates() {
		this.aryDates = [];
		for (var i = 0; i <= 6; i++) {
			// var currentDate = new Date();
			// currentDate.setDate(startDate.getDate() + i);
			// let startEpoch = currentDate.setHours(0,0,0,0)
			// let endEpoch = currentDate.setHours(23,59,59,999);
			var date = moment.tz(this.IstTimeZone).add(i,'days');
			let startEpoch = moment.tz(this.IstTimeZone).add(i,'days').startOf("day");
			let endEpoch = moment.tz(this.IstTimeZone).add(i,'days').endOf("day");
			this.aryDates.push({
				'day': this.DayAsString(date.day()),
				'date': date.get('date'),
				'startEpoch': startEpoch,
				'endEpoch': endEpoch,
				'isSelected': false
			})
		}
		return this.aryDates;
	}

	DayAsString(dayIndex: any) {
		var weekdays = new Array(7);
		weekdays[0] = "Sun";
		weekdays[1] = "Mon";
		weekdays[2] = "Tue";
		weekdays[3] = "Wed";
		weekdays[4] = "Thu";
		weekdays[5] = "Fri";
		weekdays[6] = "Sat";
		return weekdays[dayIndex];
	}
	  
	savePlayer(player) {
		this.player = player;
	}

	onStateChange(event) {
	}

	changeDate(index: any) {
		let selectedIndex = this.aryDates.map(function(x) {
			return x['isSelected'];
		}).indexOf(true)

		if(selectedIndex != -1) {
			this.aryDates[selectedIndex].isSelected = false
			this.aryDates[index].isSelected = true
			this.startEpoch = this.aryDates[index].startEpoch
			this.endEpoch = this.aryDates[index].endEpoch
			this.movieAggregation()
		}
	}

	openPlayer() {
		var video_id = this.movieData.Trailers[0].TrailerUrl.split('v=')[1];
		var ampersandPosition = video_id.indexOf('&');
		if(ampersandPosition != -1) {
			video_id = video_id.substring(0, ampersandPosition);
		}
		var data = {
			'url':video_id
		}
		const dialogRef = this.dialog.open(YoutubeDialogComponent, { panelClass: 'myapp-no-padding-dialog',
				data: data
				
		});
		dialogRef.afterClosed().subscribe(result => {
		});
	}

	bookTicket(show, theatreObj) {
		let showObj = {};
		showObj['time'] = show;
		if(this.selectedDate && this.selectedDate.length) {
			let bookingDate = this.selectedDate.map(function(x) {
				return x['isSelected'];
			}).indexOf(true)
			if(bookingDate != -1) {
				// this.movieBookingDate = this.datePipe.transform(new Date(this.selectedDate[bookingDate].startEpoch), 'yyyy-MM-dd');
					this.movieBookingDate = (this.selectedDate[bookingDate].startEpoch).format("YYYY-MM-DD");
			}
			showObj['date'] = this.movieBookingDate;
		}
		var theatreName = theatreObj.TheatreName.toLowerCase();
		if(!theatreName.includes('chakravarthy')) {
			let dataObj= {
				'showDetails' : showObj,
				'theatreDetails': theatreObj
			};
			const dialogRef = this.dialog.open(MovieDailogComponent, {
				width: '500px',
				disableClose: true,
				data: dataObj
			});
			dialogRef.afterClosed().subscribe(result => {
				if(result) {
					this.router.navigate(['basket']);
				}
			});
		}
	}
	isToolTipValid(theatre){
		var theatreName = theatre.TheatreName.toLowerCase();
		if(theatre.AvailableSeats != 0 && !theatreName.includes('chakravarthy')) {
			return false
		} else {
			return true
		}
		
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons/movie_icon/movie_small_card.jpg";
	}

	bannerImageErrorHandler(event) {
		event.target.src = "../../assets/images/no_movie_banner.jpg";
	}

}
