import { Component, OnInit } from '@angular/core';
import { NagercoilService } from '../_services/nagercoil.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SessionexpiredComponent } from '../sessionexpired/sessionexpired.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	public isCollapsed = true;
	public user: object;
	public allBasket: Object[];
	public basketCount: number;
	constructor(private router: Router, private Service: NagercoilService, public dialog: MatDialog) { }

	ngOnInit() {
		this.Service.basketCount.subscribe(count => this.basketCount = count)
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.Service.changeUser(this.user)
		this.Service.userData.subscribe(user => this.user = user)
		this.getUser()
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationStart) {
				this.getBasket()
			}
		});
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0)
		});
	}
	signOut() {
		localStorage.setItem('userCredential', null);
		this.user = null;
		this.router.navigate(['/']);
	}

	getUser() {
		if (this.user) {
			let route = 'user'
			this.Service.get(route, this.user['token']).then(response => {
				if (response.data) {
					this.getBasket();
				} else if (!response.status) {
					this.user = null;
					localStorage.setItem('userCredential', null);
					const dialogRef = this.dialog.open(SessionexpiredComponent, {
						width: '500px',
						height: '270px',
						disableClose: true,
					});
					dialogRef.afterClosed().subscribe(result => {
					});
				}
			});
		}


	}

	getBasket() {
		if (this.user) {
			let route = 'v2/product';
			this.Service.get(route, this.user['token']).then(response => {
				if (response.data) {
					this.allBasket = response.data.filter(function (product) {
						return product['ForLater'] == 0
					});
					this.Service.changeCount(this.allBasket.length)
				}
			});
			// 	else if(!response.status) {
			// 		this.user = null;
			// 		localStorage.setItem('userCredential', null);
			// 		const dialogRef = this.dialog.open(SessionexpiredComponent, {
			// 			width: '500px',
			// 			height: '270px',
			// 			disableClose: true,
			// 		});
			// 		dialogRef.afterClosed().subscribe(result => {
			// 		});
			// 	}
		}
	}

	explore(category: any) {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (category == 'food_and_drinks') {
			if (this.user) {
				this.router.navigate(['restaurants']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if (category == 'groceries') {
			if (this.user) {
				this.router.navigate(['grocery']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if (category == 'medicines') {
			if (this.user) {
				this.router.navigate(['medicine']);
			} else {
				this.router.navigate(['signin']);
			}
		}
		// else if(category == 'movies') {
		// 	if(this.user) {
		// 		this.router.navigate(['movie']);
		// 	} else {
		// 		this.router.navigate(['signin']);
		// 	}
		// }
		else if (category == 'tours') {
			if (this.user) {
				this.router.navigate(['tour']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if (category == 'travels') {
			if (this.user) {
				this.router.navigate(['travel']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if (category == 'computer-stores') {
			if (this.user) {
				this.router.navigate(['computer-stores']);
			} else {
				this.router.navigate(['signin']);
			}
		}
		else if (category == 'basket') {
			if (this.user) {
				this.router.navigate(['basket']);
			} else {
				this.router.navigate(['signin']);
			}
		}
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/profile/fakedb.svg";
	}

}
