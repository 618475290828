import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NagercoilService } from '../_services/nagercoil.service';
import { Router } from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-password-dailog',
  templateUrl: './password-dailog.component.html',
  styleUrls: ['./password-dailog.component.css']
})
export class PasswordDailogComponent implements OnInit {

  public user: object;
  public passwordForm: FormGroup;
  public errorMsg : boolean = false;
  public loader: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private fb: FormBuilder, private router: Router, private Service: NagercoilService, public dialogRef: MatDialogRef<PasswordDailogComponent>, private toastr: ToastrService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userCredential'));
    this.passwordForm = this.fb.group({
		old_password: ['', Validators.compose([Validators.required])],
		new_password: ['', Validators.compose([Validators.required])],
		confirm_password: ['', Validators.compose([Validators.required])],
	});
  }

  	changePassword(){
		if(this.passwordForm.invalid){
			return;
		}
		this.loader = true;
		let route = 'reset/password';
		let token = this.user['token'];
		let params = {
			"old_password": this.passwordForm.value.old_password,
			"new_password": this.passwordForm.value.new_password,
			"confirm_password": this.passwordForm.value.confirm_password,
		}
		this.Service.put(route, token, params).then(response => {
			this.loader = false;
			if(response.status) {
				this.toastr.success(response.messages[0], 'Success');
				this.dialogRef.close(true)
			} else {
				this.toastr.error(response.messages[0], 'Error');
				this.dialogRef.close(true)
			}
		});
  	}
}
