import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AddressdailogComponent } from '../../app/addressdailog/addressdailog.component';
import { NagercoilService } from '../_services/nagercoil.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { PasswordDailogComponent } from "../password-dailog/password-dailog.component";
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	public errMsg = "";
	public user: object;
	public profileForm: FormGroup;
	public addressData: object[];
	public imgUrl: any;
	public file : any;
	public loader: boolean = false;
	public pageLoader: boolean = false;
	constructor(@Inject(DOCUMENT) private document: Document,private renderer: Renderer2,private toastr: ToastrService, private _snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder, private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.pageLoader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.profileForm = this.fb.group({
			email: [null, Validators.compose([Validators.required, Validators.email])],
			name: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			phoneNumber: [null, Validators.compose([Validators.required])],
			image: [null]
		});
		if(this.user) {
			var self = this;
			this.getUser(function(res: any) {
				if(res) {
					self.getAddress(function(res: any){
						if(res) {
							self.pageLoader = false;
						}
					})
				}
			})
			
		} else {
			this.router.navigate(['signin']);
		}
		
		
	}

	// Address CRUD

	getAddress(callback: any) {
		let token = this.user['token']
		let route = 'address'
		this.Service.get(route, token).then(response => {
			if(response.data) {
				this.addressData = response.data
				callback(true)
			} 
		});
	}

	createAddress(): void {
		const dialogRef = this.dialog.open(AddressdailogComponent, {
			width: '930px'
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.pageLoader = true;
				var self = this
				this.getAddress(function(res: any){
					if(res) {
						self.pageLoader = false;
					}
				})
			}
		});
	}

	updateAddress(addressObj: any) {
		const dialogRef = this.dialog.open(AddressdailogComponent, {
			panelClass: 'overflow_scroll_dialog',
			width: '930px',
			data:addressObj
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.pageLoader = true;
				var self = this
				this.getAddress(function(res: any){
					if(res) {
						self.pageLoader = false;
					}
				})
			}
			
		});
	}  

	deleteAddress(addressObj: any) {
        var obj = {
            dataObj: addressObj,
            component: 'addressComponent'
        }
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: obj
        });
        dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.pageLoader = true;
				var self = this
				this.getAddress(function(res: any){
					if(res) {
						self.pageLoader = false;
					}
				})
			}
            
        });
	}
	
	getUser(callback: any) {
		var token = this.user['token']
		this.Service.get('user', token).then(response => {
			
			this.renderer.removeClass(this.document.body, 'bdovflw_hi');
			if(response.data) {
				response.data.token = token
				localStorage.setItem('userCredential', null);
				localStorage.setItem('userCredential', JSON.stringify(response.data));
				this.user = JSON.parse(localStorage.getItem('userCredential'));
				if(this.user['image']) {
					this.imgUrl = this.user['image']
				}
				this.Service.changeUser(this.user)
				this.profileForm.setValue({
					email: this.user['email'],
					name: this.user['name'] ,
					phoneNumber: this.user['phone'],
					image: ''
				});
				callback(true)
			} 
			
		});
	}

	updateProfile() {

		if (this.profileForm.invalid) {
			return;
		}
		this.loader = true;
		if(this.profileForm.controls.image.value) {
			var formdata = new FormData();
			formdata.append('profile_image', this.file)
			formdata.append('name', this.profileForm.controls.name.value.trim())
			let route = 'user/' +this.user['userId']+ '?_method=PUT'
			this.Service.formData(route, this.user['token'], formdata).then(response => {
				this.loader = false;
				if(response.status) {
					this.errMsg = ""
					var self = this;
					this.getUser(function(res: any){
						if(res) {
							self.toastr.success('Profile Updated','Success');
						}
					})
					
				} else {
					self.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		} else {
			this.loader = true;
			var params = {
				name: this.profileForm.controls.name.value.trim()
			}
			let route = 'user/' + this.user['userId']
			this.Service.put(route, this.user['token'], params).then(response => {
				this.loader = false;
				if(response.status) {
					this.errMsg = ""
					var self = this;
					this.getUser(function(res:any) {
						if(res) {
							self.toastr.success('Profile Updated','Success');
						}
					})
					
				} else {
					self.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		}
	}

	onFileChanged(event: any){
		if (event.target.files && event.target.files[0]) {
           this.file = event.target.files[0]
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.imgUrl = event.target.result; 
			}
			reader.readAsDataURL(event.target.files[0]);
            
        }
	}

	changePassword() {
		const dialogRef = this.dialog.open(PasswordDailogComponent, {
			width: '500px',
		});
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/profile/fakedb.svg";
	}
}
