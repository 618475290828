import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
// import { AngularFireRemoteConfig } from "@angular/fire/remote-config";

@Injectable({
  providedIn: 'root'
})


export class ApiService {
	// private remoteConfig: AngularFireRemoteConfig
	constructor(private http: HttpClient) {

		// this.remoteConfig.changes.pipe().subscribe();
		// this.remoteConfig.fetch().then(status => console.log(status))
		// .catch((err) => {
		// 	console.error(err);
		// });
	}

	// Url = 'http://chn.adyog.com:45870/api/';
	Url = 'https://admin.nagercoil.com/api/';
	
	// Url = 'http://157.230.210.207:8043/api';
	
    auth(params:any, route: any)  {
		
		let URL = this.Url + route

		try{
			const httpOptions = {
				headers: new HttpHeaders({
				  'Content-Type':  'application/json'
				})
			};
			return this.http.post<any>(URL, params, httpOptions).pipe(map((response:any) => { 
				return response;
			}), catchError(err => {
                let obj = {
                    'status': false,
                    'err': err
                }
                return of(obj);
			}));

		} catch(e) {
			console.log(e,'error')
		}
		
	}


	get(route: any, token: any) {
		let URL = this.Url + route

		try{
			const httpOptions = {
				headers: new HttpHeaders({
					'Accept': 'application/json',
					'Content-Type':  'application/json',
				    'Authorization':  'Bearer ' + token
				})
			};
			return this.http.get<any>(URL, httpOptions).pipe(map((response:any) => { 
				return response;
			}), catchError(err => {
                let obj = {
                    'status': false,
                    'err': err
                }
                return of(obj);
            }));

		} catch(e) {
			console.log(e,'error')
		}
    }

    put(route: any, token: any, params: any) {
        let URL = this.Url + route

		try{
			var httpOptions = {
				headers: new HttpHeaders({
					'Content-Type':  'application/json',
				    'Authorization':  'Bearer ' + token
				})
			};
			return this.http.put<any>(URL, params, httpOptions).pipe(map((response:any) => { 
				return response;
			}));

		} catch(e) {
			console.log(e,'error')
		}
    }

    post(route: any, token: any, params: any) {
        let URL = this.Url + route

		try{
			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type':  'application/json',
				    'Authorization':  'Bearer ' + token
				})
			};
			return this.http.post<any>(URL, params, httpOptions).pipe(map((response:any) => { 
				return response;
			}));

		} catch(e) {
			console.log(e,'error')
		}
    }

    delete(route: any, token: any) {
        let URL = this.Url + route

		try{
			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type':  'application/json',
				    'Authorization':  'Bearer ' + token
				})
			};

			return this.http.delete<any>(URL, httpOptions).pipe(map((response:any) => { 
				return response;
			}));

		} catch(e) {
			console.log(e,'error')
		}
	}

	formData(route: any, token: any, params: any) {
        let URL = this.Url + route

		try{
			const httpOptions = {
				headers: new HttpHeaders({
					'Authorization':  'Bearer ' + token
				})
			};
			return this.http.post<any>(URL, params, httpOptions).pipe(map((response:any) => { 
				return response;
			}));

		} catch(e) {
			console.log(e,'error')
		}
    }
	

}
