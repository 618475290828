import { ResturantComponent } from './resturant/resturant.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
// import { FoodComponent } from './food/food.component';
import { GroceryComponent } from './grocery/grocery.component';
import { MedicineComponent } from './medicine/medicine.component';
import { MovieComponent } from './movie/movie.component';
import { MovieDetailsComponent } from './movie-details/movie-details.component'
import { TravelComponent } from './travel/travel.component';
import { TourDetailsComponent } from './tour-details/tour-details.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { BasketComponent } from './basket/basket.component';
import { OrdersComponent } from './orders/orders.component';
import { ContactComponent } from './contact/contact.component';
import { TourComponent } from './tour/tour.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TourPlaceComponent } from './tour-place/tour-place.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FoodListComponent } from './food-list/food-list.component';
import { GroceryStoresComponent } from './grocery-stores/grocery-stores.component';
import { GroceryListComponent } from "./grocery-list/grocery-list.component";
import { ComputerListComponent } from './computer-list/computer-list.component';
import { ComputerStoresComponent } from './computer-stores/computer-stores.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, pathMatch: "full" },
	{ path: 'home', component: HomeComponent, pathMatch: "full" },
	// { path: 'food', component: FoodComponent, pathMatch: "full" },
	{ path: 'restaurants', component: ResturantComponent, pathMatch: "full" },
	{ path: 'restaurant/:id', component: FoodListComponent, pathMatch: "full" },
	// { path: 'food/:id', component: FoodListComponent, pathMatch: "full" },
	// { path: 'grocery', component: GroceryComponent, pathMatch: "full" },
	// { path: 'grocery/:id', component: GroceryComponent, pathMatch: "full" },
	{ path: 'grocery', component: GroceryStoresComponent, pathMatch: "full" },
	{ path: 'grocery/:id', component: GroceryListComponent, pathMatch: "full" },
	{ path: 'medicine', component: MedicineComponent, pathMatch: "full" },
	{ path: 'medicine/:id', component: MedicineComponent, pathMatch: "full" },
	{ path: 'travel', component: TravelComponent, pathMatch: "full" },
	{ path: 'computer-stores', component: ComputerStoresComponent, pathMatch: "full" },
	{ path: 'computer-stores/:id', component: ComputerListComponent, pathMatch: "full" },
	// { path: 'movie', component: MovieComponent, pathMatch: "full" },
	// { path: 'movie/:id', component: MovieDetailsComponent, pathMatch: "full" },
	{ path: 'signin', component: SigninComponent, pathMatch: "full" },
	{ path: 'signup', component: SignupComponent, pathMatch: "full" },
	{ path: 'profile', component: ProfileComponent, pathMatch: "full" },
	{ path: 'basket', component: BasketComponent, pathMatch: "full" },
	{ path: 'orders', component: OrdersComponent, pathMatch: "full" },
	{ path: 'contact', component: ContactComponent, pathMatch: "full" },
	{ path: 'tour', component: TourComponent, pathMatch: "full" },
	{ path: 'tour/:code', component: TourPlaceComponent, pathMatch: "full" },
	{ path: 'tour/:code/:id', component: TourDetailsComponent, pathMatch: "full" },
	{ path: 'terms', component: TermsComponent, pathMatch: "full" },
	{ path: 'privacy', component: PrivacyComponent, pathMatch: "full" },
	{ path: 'about', component: AboutusComponent, pathMatch: "full" },
	// { path: 'food-list', component: FoodListComponent, pathMatch: "full" },
	{ path: '**', redirectTo: '/404' },
	{ path: '404', component: PageNotFoundComponent },
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
