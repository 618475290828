import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NagercoilService } from '../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { StatusdialogComponent } from '../statusdialog/statusdialog.component';
import { MatDialog } from '@angular/material';
import {Observable} from 'rxjs';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-travel',
	templateUrl: './travel.component.html',
	styleUrls: ['./travel.component.css'],
	providers: [DatePipe],
})

export class TravelComponent implements OnInit {

	public user: object;
	public form: FormGroup;
	public travelData: any;
	public fromPlace : any;
	public toPlace : any;
	public minDate: any;
	public fare:any;
	public carArr = [];
	public carDisabled: boolean = true;
	public selectedRoute: any;
	public pageLoader:boolean = false;
	filteredFrom: Observable<any>;
	filteredTo: Observable<any>;
	public currentDate = new Date();
	public istMoment: any;
	public IstTimeZone = "Asia/Kolkata";

	constructor(private router: Router,private toastr: ToastrService, private route: ActivatedRoute, private Service: NagercoilService, private fb: FormBuilder, private datePipe: DatePipe, public dialog: MatDialog) { 
		this.form =  this.fb.group({
			'fromSelect': ['', Validators.compose([Validators.required])],
			'toSelect': ['', Validators.compose([Validators.required])],
			'carName': ['', Validators.compose([Validators.required])],
			'date': ['', Validators.compose([Validators.required])],
		});
	}

	ngOnInit() {
		this.istMoment = moment(this.currentDate).tz(this.IstTimeZone).format("YYYY-MM-DD HH:mm:ss");
		this.minDate = new Date(this.istMoment);
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.pageLoader = true;
		if(!this.user) {
			this.router.navigate(['signin']);
		}  else {
			// this.minDate = new Date(this.istMoment);
			let route = 'travelcars';
			this.Service.get(route, this.user['token']).then(response => {
				this.pageLoader = false;
				if(response.data) {
					let responseArr = response.data;
					for(let i=0; i< responseArr.length; i++) {
						this.carArr.push({
							'id' : responseArr[i].Id,
							'carName' : responseArr[i].Name,
							'CarImage' : responseArr[i].Images[0],
							'fare' : responseArr[i].BookingCharges
						})
					}
				} else {
					this.pageLoader = false;
					this.toastr.error('Something Went Wrong, Please try after sometimes', 'Error');
				}
			});
			this.formControlValueChanged();
		}
	}

	formControlValueChanged() {
		this.form.controls['carName'].valueChanges.subscribe(checked => {
			if(checked) {
				this.fare = checked.fare;
			}
		});
	}

	swap() {
		if(this.form.value.fromSelect && this.form.value.toSelect) {
			let from = this.form.value.fromSelect
			let to = this.form.value.toSelect
			

			this.form.patchValue({
				'fromSelect': to,
				'toSelect': from
			});
		}
	}

	onSubmit() { 
		let route = "v2/product";
    	let token = this.user['token'];
		let params = {};
		var items = {
			'from':this.form.value.fromSelect.trim(),
			'to':this.form.value.toSelect.trim(),
			'travelCarId' :this.form.value.carName.id,
			'dateTime' : this.datePipe.transform(this.form.value.date, 'yyyy-MM-dd HH:mm:00'),
			'name' : this.user['name'].trim(),
			'email' : this.user['email'].trim(),
			'mobile' : parseInt(this.user['phone'].trim()),
		};
		params['items'] = items;
		params['category'] = 5;
		this.Service.post(route, token, params).then(response => {
			if(response.status) {
				const dialogRef = this.dialog.open(StatusdialogComponent, {
					width: '500px',
					height: 'auto',
					disableClose: true,
					data: 'travelComponent'
				});
				dialogRef.afterClosed().subscribe(result => {
					this.form.reset();
					this.router.navigate(['basket']);
				});
			} else {
				this.toastr.error(response.messages[0]);
			}
		});
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons8-car-64.png";
	}
}
