import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NagercoilService } from '../_services/nagercoil.service';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	public user: object;
	public categoryData: object[];
	public loader: boolean = false;

    constructor(@Inject(DOCUMENT) private document: Document,private renderer: Renderer2,private router: Router, private Service: NagercoilService) { }

    ngOnInit() {
		this.loader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.getCategory()
	}
	
	getCategory() {
		let token = ""
		let route = 'category'
		this.Service.get(route, token).then(response => {
			if(response.data) {
				this.categoryData = response.data
				this.loader = false;
				this.renderer.removeClass(this.document.body, 'bdovflw_hi');
			} 
		});
	}

	explore(category: any) {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(category.name == 'food_and_drinks') {
			if(this.user) {
				this.router.navigate(['restaurants']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if(category.name == 'groceries') {
			if(this.user) {
				this.router.navigate(['grocery']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if(category.name == 'medicines') {
			if(this.user) {
				this.router.navigate(['medicine']);
			} else {
				this.router.navigate(['signin']);
			}
		} 
		// else if(category.name == 'movies') {
		// 	if(this.user) {
		// 		this.router.navigate(['movie']);
		// 	} else {
		// 		this.router.navigate(['signin']);
		// 	}
		// } 
		else if(category.name == 'tours') {
			if(this.user) {
				this.router.navigate(['tour']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if(category.name == 'travels'){
			if(this.user) {
				this.router.navigate(['travel']);
			} else {
				this.router.navigate(['signin']);
			}
		} else if(category.name == 'computers'){
			if(this.user) {
				this.router.navigate(['computer-stores']);
			} else {
				this.router.navigate(['signin']);
			}
		}
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/no-image.jpg";
	}
}
