import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NagercoilService } from '../_services/nagercoil.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-food',
	templateUrl: './food.component.html',
	styleUrls: ['./food.component.css']
})

export class FoodComponent implements OnInit {

	public form: FormGroup;
	public images = [];
	public isFormValid: boolean = false;
	public user: object;
	public files: any;
	public id: any;
	public editData: any;
	public deletedItems: Object[];
	public deletedImages: Object[];
	public isValidators: boolean = true;
	public loader: boolean = false;
	public pageLoader: boolean = false;
	public fileArray: any;
	constructor(private route: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder, private router: Router, private Service: NagercoilService) {
		this.form = fb.group({
			'resName': ['', Validators.compose([Validators.required])],
			'itemFormArray': fb.array([]),
			'comments': [''],
			'description': [''],
			'Images': [''],
		});
	}

	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (!this.user) {
			this.router.navigate(['signin']);
		} else {
			this.addItem();
			this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : null
			if (this.id) {
				this.pageLoader = true;
				this.deletedItems = [];
				this.deletedImages = [];
				let route = 'v2/product/' + this.id;
				this.Service.get(route, this.user['token']).then(response => {
					this.pageLoader = false;
					if (response.data) {
						this.editData = response.data
						if (this.editData['Items'] && this.editData['Items'].length && this.editData['Restaurant']) {
							this.form = this.fb.group({
								'resName': ['', Validators.compose([Validators.required])],
								'itemFormArray': this.fb.array([]),
								'comments': [''],
								'description': [''],
								'Images': [''],
							});
							for (let j = 0; j < this.editData['Items'].length; j++) {
								var obj = this.fb.group({
									'itemName': [this.editData['Items'][j]['Item'], Validators.compose([Validators.required])],
									'quantity': [this.editData['Items'][j]['Quantity'], Validators.compose([Validators.required])],
									'id': this.editData['Items'][j]['ItemId'],
									'itemOrder': this.editData['Items'][j]['ItemOrder']

								});
								this.itemFormArray.push(obj);
							}


						} else {
							this.form = this.fb.group({
								'resName': [''],
								'itemFormArray': this.fb.array([]),
								'comments': [''],
								'description': [''],
								'Images': [''],
							});
							this.addItem();
						}

						if (this.editData['Images'] && this.editData['Images'].length) {
							for (let j = 0; j < this.editData['Images'].length; j++) {
								this.images.push(this.editData['Images'][j].ImageUrl)
							}
						}

						this.form.patchValue({
							'resName': this.editData['Restaurant'] ? this.editData['Restaurant'] : '',
							'description': this.editData['Description'] ? this.editData['Description'] : '',
							'comments': this.editData['Comments'] ? this.editData['Comments'] : '',
							'Images': '',
						});
					}
				});
			}

		}
	}


	get itemFormArray() {
		return this.form.get('itemFormArray') as FormArray
	}

	onSelectFile(event: any) {
		if (event.target.files && event.target.files.length != 0) {
			var files = event.target.files.length;
			this.files = event.target.files;
			if (this.fileArray && this.fileArray.length) {
				this.fileArray = this.fileArray.concat(Array.from(this.files));
			} else {
				this.fileArray = Array.from(this.files)
			}

			for (let i = 0; i < files; i++) {
				var reader = new FileReader();
				var Imagesize = Math.round(event.target.files[i].size / 1024);

				if (Imagesize < 2048) {
					reader.readAsDataURL(event.target.files[i]);
					reader.onload = (event: any) => {
						this.images.push(event.target.result);
					}
					this.isFormValid = true;
				}
				else {
					if (this.images && this.images.length == 0) {
						this.form.controls['Images'].setValue('');
					}

					this.toastr.error('Image Size Exceeds the Maximum Limit of 2MB', 'Error');
				}
			}
		}
	}

	cancelImage(index: any) {
		if (this.id) {
			let Index = this.editData.Images.map(function (x) {
				return x['ImageUrl'];
			}).indexOf(this.images[index])
			if (Index != -1) {
				this.deletedImages.push(this.editData.Images[Index].ImageId)
			}
		}

		this.images.splice(index, 1);
		if (this.fileArray && this.fileArray.length) {
			this.fileArray.splice(index, 1);
		}
		if (this.images.length == 0) {
			this.form.controls['Images'].setValue('');
		}
	}


	addItem() {

		let fg = this.fb.group({
			'itemName': ['', Validators.compose([Validators.required])],
			'quantity': ['', Validators.compose([Validators.required])],
		});
		this.itemFormArray.push(fg);
	}

	deleteItem(index: number) {
		if (this.id) {
			if (this.form.value.itemFormArray[index].id) {
				let Index = this.editData.Items.map(function (x) {
					return x['ItemId'];
				}).indexOf(this.form.value.itemFormArray[index].id)
				if (Index != -1) {
					var tempObject = JSON.parse(JSON.stringify(this.editData.Items[Index]))
					this.deletedItems.push({
						"id": tempObject['ItemId'],
						"itemName": tempObject['Item'],
						"quantity": tempObject['Quantity'],
						"itemOrder": tempObject['ItemOrder'],
						"category": 1,
						"action": "delete"
					})
				}
			}
		}
		this.itemFormArray.removeAt(index);
	}

	onSubmit() {
		if (!this.id) {
			this.loader = true;
			var formdata = new FormData();
			var items: any;
			items = JSON.parse(JSON.stringify(this.form.value.itemFormArray))
			for (let i = 0; i < items.length; i++) {
				if (items[i].quantity && items[i].itemName) {
					items[i]["itemOrder"] = i + 1
					items[i]["category"] = 1
					items[i]["action"] = "add"
					items[i]["quantity"] = items[i].quantity.trim()
					items[i]["itemName"] = items[i].itemName.trim()
				}

			}
			formdata.append('restaurant_name', this.form.value.resName.trim());
			formdata.append('items', JSON.stringify(items));

			if (this.fileArray && this.fileArray.length) {
				for (let i = 0; i < this.fileArray.length; i++) {
					formdata.append('images[' + i + ']', this.fileArray[i]);
				}
			}

			if (this.form.value.description) {
				formdata.append('description', this.form.value.description.trim());
			}

			if (this.form.value.comments) {
				formdata.append('comments', this.form.value.comments.trim());
			}

			formdata.append('category', '1');

			let route = 'v2/product'
			this.Service.formData(route, this.user['token'], formdata).then(response => {
				this.loader = false;
				if (response.status) {
					this.toastr.success('Added Successfully', 'Success');
					this.form.reset({ emitEvent: false })
					this.images = []
					this.router.navigate(['basket']);
				} else {
					this.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		} else {
			this.loader = true;
			var formdata = new FormData();
			if ((!this.files || !this.files.length) && (!this.deletedImages || !this.deletedImages.length) && (!this.deletedItems || !this.deletedItems.length) && (!Object.keys(this.editData).length || !this.editData['Items'] || !this.editData['Items'].length) && !this.form.value.resName && (!this.form.value.itemFormArray || (this.form.value.itemFormArray && this.form.value.itemFormArray.length && !this.form.value.itemFormArray[0].itemName && !this.form.value.itemFormArray[0].quantity))) {
				this.router.navigate(['basket'])
			} else {
				if (this.form.value.resName || this.form.value.resName == '') {
					var items = JSON.parse(JSON.stringify(this.form.value.itemFormArray))
					if (items && items.length) {
						for (let i = 0; i < items.length; i++) {
							if (items[i].id) {
								let Index = this.editData.Items.map(function (x) {
									return x['ItemId'];
								}).indexOf(items[i].id)
								if (Index != -1) {
									if (!items[i].quantity && !items[i].itemName) {
										let delIndex = this.deletedItems.map(function (x) {
											return x['id'];
										}).indexOf(this.editData.Items[Index]['ItemId'])
										if (delIndex == -1) {
											this.deletedItems.push({
												"id": this.editData.Items[Index]['ItemId'],
												"itemName": this.editData.Items[Index]['Item'],
												"quantity": this.editData.Items[Index]['Quantity'],
												"itemOrder": this.editData.Items[Index]['ItemOrder'],
												"category": 1,
												"action": "delete"
											})
										}

									} else {
										if (this.editData.Items[Index]['Quantity'] == items[i]['quantity'] && this.editData.Items[Index]['Item'] == items[i]['itemName']) {
											items[i]["category"] = 1
											items[i]["action"] = "none"
											items[i]["quantity"] = items[i].quantity.trim()
											items[i]["itemName"] = items[i].itemName.trim()
										} else {
											items[i]["category"] = 1
											items[i]["action"] = "update"
											items[i]["quantity"] = items[i].quantity.trim()
											items[i]["itemName"] = items[i].itemName.trim()
										}
									}
								}
							} else {
								if (items[i].quantity && items[i].itemName) {
									items[i]["itemOrder"] = i != 0 ? items[i - 1].itemOrder + 1 : 1
									items[i]["category"] = 1
									items[i]["action"] = "add"
									items[i]["quantity"] = items[i].quantity.trim()
									items[i]["itemName"] = items[i].itemName.trim()
								}

							}
						}

						if (this.deletedItems && this.deletedItems.length) {
							for (let i = 0; i < this.deletedItems.length; i++) {
								let delIndex = items.map(function (x) {
									return x['id'];
								}).indexOf(this.deletedItems[i]['id'])
								if (delIndex != -1) {
									items.splice(delIndex, 1)
								}
								items.push(this.deletedItems[i])

							}
						}

						var itemArray = []
						for (let i = 0; i < items.length; i++) {
							if (items[i].itemName && items[i].quantity) {
								itemArray.push(items[i])
							}
						}

						formdata.append('items', JSON.stringify(itemArray));

						if (this.form.value.resName) {
							formdata.append('restaurant_name', this.form.value.resName.trim());
						}
					}


				}

				if (this.fileArray && this.fileArray.length) {
					for (let i = 0; i < this.fileArray.length; i++) {
						formdata.append('images[' + i + ']', this.fileArray[i]);
					}
				}

				if (this.form.value.description) {
					formdata.append('description', this.form.value.description.trim());
				}

				if (this.form.value.comments) {
					formdata.append('comments', this.form.value.comments.trim());
				}


				formdata.append('category', '1');

				if (this.deletedImages && this.deletedImages.length) {
					formdata.append('delete_image_ids', JSON.stringify(this.deletedImages));
				}

				let route = 'v2/product/' + this.id + '?_method=PUT'
				this.Service.formData(route, this.user['token'], formdata).then(response => {
					if (response.status) {
						this.toastr.success('Updated Successfully', 'Success');
						this.form.reset({ emitEvent: false })
						this.images = []
						this.router.navigate(['basket']);
					} else {
						this.loader = false;
						this.toastr.error('Please try after sometime.', 'Something went wrong!');
					}
				});
			}
		}

	}

	ngOnDestroy(): void {
	}

}
