import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NagercoilService } from '../_services/nagercoil.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
	public user: object;
	public signInSection: boolean = true;
	public signInForm: FormGroup;
	public forgetForm: FormGroup;
	public OTPForm: FormGroup;
	public mode = 'signin';
	public errorMsg: boolean = false;
	public errorData: string[];
	public otpErrorMsg: boolean = false;
	public otpSuccess: boolean = false;
	public forgetResult: boolean = false;
	public loader: boolean = false;
	public otpStatus: boolean = false;
	constructor(private fb: FormBuilder, private toastr: ToastrService, private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.signInForm = this.fb.group({
			password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
			username: [null, Validators.compose([Validators.required])]
		});

		this.OTPForm = this.fb.group({
			otp: [null, Validators.compose([Validators.required])],
		});

		this.forgetForm = this.fb.group({
			phone: [null, Validators.compose([Validators.required])]
		});

		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (this.user) {
			this.router.navigate(['/']);
		}
	}

	SignIn() {
		if (this.signInForm.invalid) {
			return;
		}
		this.loader = true;
		let params = {
			'login': this.signInForm.value.username.trim(),
			'password': this.signInForm.value.password
		}
		let route = 'login'
		this.Service.auth(params, route).then(resp => {
			if (resp.access_token) {
				if (resp.mobile_verified) {
					this.Service.get('user', resp.access_token).then(response => {
						if (response.data) {
							if (response.data.role == 7) {
								response.data.token = resp.access_token
								localStorage.setItem('userCredential', null);
								localStorage.setItem('userCredential', JSON.stringify(response.data));
								localStorage.removeItem("tempToken");
								this.router.navigate(['/']);
							} else {
								this.loader = false;
								this.toastr.error('unauthorized', 'Error!');
							}
						}
					});
				} else {
					localStorage.setItem('tempToken', JSON.stringify(resp.access_token));
					localStorage.setItem('userCredential', null);
					this.loader = false;
					this.signInSection = false;
					this.generateOtp();
				}
			} else if (!resp.status) {
				if (resp.messages) {
					if (resp.messages.length || resp.messages) {
						this.loader = false;
						this.errorData = resp.messages;
						this.errorMsg = true;
					}
					else {
						this.loader = false;
						this.errorData = ['Something went wrong! Please try after sometime'];
						this.errorMsg = true;
					}
				} else {
					this.loader = false;
					this.errorData = ['Something went wrong! Please try after sometime'];
					this.errorMsg = true;
				}
			}
		});
	}

	OTPVerfication() {
		if (this.OTPForm.invalid) {
			return;
		}
		this.loader = true;
		let params = {
			'verification_code': parseInt(this.OTPForm.value.otp)
		}
		let token = JSON.parse(localStorage.getItem('tempToken'));
		let route = 'mobile/verify'
		this.Service.put(route, token, params).then(response => {
			if (response.status) {
				this.Service.get('user', token).then(response => {
					if (response.data) {
						response.data.token = JSON.parse(localStorage.getItem('tempToken'));
						localStorage.setItem('userCredential', null);
						localStorage.setItem('userCredential', JSON.stringify(response.data));
						this.router.navigate(['/']);
					} else {
						localStorage.setItem('userCredential', null);
						this.loader = false;
						this.otpSuccess = false;
						this.errorMsg = true;
					}
				});
			} else {
				if (response.messages.length) {
					this.loader = false;
					this.otpSuccess = false;
					this.errorData = response.messages;
					this.otpErrorMsg = true;
				}
				else {
					this.errorData = ['Something went wrong! Please try after sometime'];
					this.loader = false;
					this.otpSuccess = false;
					this.otpErrorMsg = true;
				}
			}
		});
	}

	generateOtp() {
		this.otpStatus = true;
		let route = 'mobile/verify/resend';
		let token = JSON.parse(localStorage.getItem('tempToken'));
		this.Service.get(route, token).then(response => {
			if (response.status) {
				if (response.messages.length) {
					this.errorData = response.messages;
					this.otpErrorMsg = true;
					if (this.errorData[0] == "Verification code has been sent") {
						this.otpStatus = false;
						this.otpSuccess = true;
					}
				} else {
					this.otpStatus = false;
					this.errorData = ['Something went wrong! Please try after sometime'];
					this.otpErrorMsg = true;
					this.otpSuccess = false;
				}
			}
			else {
				this.otpStatus = false;
				this.errorData = ['Something went wrong! Please try after sometime'];
				this.otpErrorMsg = true;
				this.otpSuccess = false;
			}
		});

	}

	forget() {
		if (this.forgetForm.invalid) {
			return;
		}
		this.loader = true;
		let params = {
			'username': parseInt(this.forgetForm.value.phone),
		}
		let route = 'forgot/password'
		this.Service.put(route, '', params).then(response => {
			if (response.status) {
				this.loader = false;
				this.forgetForm.reset()
				this.forgetResult = true;
				this.toastr.success('New Password has been send to your mobile number', 'Success');
			} else {
				this.loader = false;
				this.forgetResult = false;
				this.toastr.error('Please try after sometime.', 'Something went wrong!');
			}
		});
	}
	resendForgotPassword() {
		this.forgetResult = false;
	}
	onForgot() {
		this.mode = 'forget'
		this.forgetResult = false;
	}
}
