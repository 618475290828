import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { NagercoilService } from '../_services/nagercoil.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
	'text': string;
	"d_id": string;
	'u_id' : string;
	's_id':string;
	'mode':string;
}


@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	public user: object;
	public errorMsg : boolean = false;
	public loader: boolean = false;
	public cancelOrder:boolean = false;
	constructor(private toastr: ToastrService, public dialogRef: MatDialogRef<ConfirmationDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData, private Service: NagercoilService) { }
	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(this.data['component'] == 'orderComponent' ) {
			this.cancelOrder = true;
		} else {
			this.cancelOrder = false;
		}

	}
	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.loader = true;
		if(this.data['component'] == 'basketComponent' ) {
				let route = 'v2/product/'+ this.data['dataObj']['ProductId'];
				this.Service.delete(route, this.user['token']).then(response => {
				if(response.status) {
					this.toastr.success('Removed Successfully', 'Success');
					this.dialogRef.close(true);
				} else {
					this.loader = false;
					this.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		}else if(this.data['component'] == 'addressComponent' ) {
			let route = 'address/' + this.data['dataObj']['addressId'];
			this.Service.delete(route, this.user['token']).then(response => {
				if(response.status) {
					this.toastr.success('Address Deleted Successfully','Success');
					this.dialogRef.close(true);
				} else {
					this.loader = false;
					this.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		}else if(this.data['component'] == 'orderComponent' ) {
			let params = "";
			let route = 'order/' + this.data['dataObj']['OrderId'];
			this.Service.put(route, this.user['token'], params).then(response => {
				if(response.status) {
					this.toastr.success('your order has been cancelled', 'Success');
					this.dialogRef.close();
				} else {
					this.loader = false;
					this.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		}
	}
}
