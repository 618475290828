import { Component, OnInit, Renderer2, Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TrackorderdialogComponent } from '../trackorderdialog/trackorderdialog.component';
import { Router } from '@angular/router';
import { NgxGalleryOptions } from 'ngx-gallery';
import { NagercoilService } from "../_services/nagercoil.service";
import { DOCUMENT } from '@angular/common';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.css']
})

export class OrdersComponent implements OnInit {

	public images: any[] = [
		'../../assets/images/ind_img/food.jpg',
		'../../assets/images/ind_img/grocerys.jpg',
		'../../assets/images/ind_img/computers.jpg'	
	]
	public user: Object;
	public galleryOptions: NgxGalleryOptions[];
	public orderData: Object[];
	public pendingOrders: object[];
	public cancelledOrders: object[];
	public loader: boolean = false;

// 	@ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

// 	@HostListener('window:resize', ['$event'])

// 	onResize(event) {
//     this.cdkVirtualScrollViewport.checkViewportSize();
//   }

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, public dialog: MatDialog, private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.loader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (!this.user) {
			this.router.navigate(['signin']);
		}
		this.getOrder();
		this.galleryOptions = [
			{
				width: '250px',
				height: '200px',
				thumbnailsColumns: 4,
				preview: true,
				thumbnails: false,
				previewCloseOnClick: true,
				previewCloseOnEsc: true,
				previewKeyboardNavigation: true,
			}
		];

	}

	getOrder() {
		let route = 'v2/order';
		this.Service.get(route, this.user['token']).then(response => {
			this.loader = false;
			this.renderer.removeClass(this.document.body, 'bdovflw_hi');
			if (response.data) {
				this.orderData = response.data;
				if (this.orderData && this.orderData.length) {
					this.pendingOrders = this.orderData.filter(function (product) {
						return product['OrderStatus'] == 'pending' || product['OrderStatus'] == 'in progress' || product['OrderStatus'] == 'on the way' || product['OrderStatus'] == 'confirmed' || product['OrderStatus'] == 'delivered';
					});

					this.pendingOrders.sort(function(a, b){return b['OrderId'] - a['OrderId']});

					this.cancelledOrders = this.orderData.filter(function (product) {
						return product['OrderStatus'] == 'cancelled';
					});

					this.cancelledOrders.sort(function(a, b){return b['OrderId'] - a['OrderId']});
				}

				if (this.pendingOrders && this.pendingOrders.length) {
					for (let i = 0; i < this.pendingOrders.length; i++) {
						for (let j = 0; j < this.pendingOrders[i]['Products'].length; j++) {
							var tempArr = []
							if (this.pendingOrders[i]['Products'][j]['CategoryId'] == 1 || this.pendingOrders[i]['Products'][j]['CategoryId'] == 2 || this.pendingOrders[i]['Products'][j]['CategoryId'] == 7) {
								if (this.pendingOrders[i]['Products'][j]['Items'][0]['Images']) {
									for (let k = 0; k < this.pendingOrders[i]['Products'][j]['Items'][0]['Images'].length; k++) {
										tempArr.push({
											'medium': this.pendingOrders[i]['Products'][j]['Items'][0]['Images'][k],
											'big': this.pendingOrders[i]['Products'][j]['Items'][0]['Images'][k]
										})
										if (k == this.pendingOrders[i]['Products'][j]['Items'][0]['Images'].length - 1) {
											this.pendingOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							} else if (this.pendingOrders[i]['Products'][j]['CategoryId'] == 3) {
								if (this.pendingOrders[i]['Products'][j]['Images']) {
									for (let k = 0; k < this.pendingOrders[i]['Products'][j]['Images'].length; k++) {
										tempArr.push({
											'medium': this.pendingOrders[i]['Products'][j]['Images'][k].ImageUrl,
											'big': this.pendingOrders[i]['Products'][j]['Images'][k].ImageUrl
										})
										if (k == this.pendingOrders[i]['Products'][j]['Images'].length - 1) {
											this.pendingOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							} else if (this.pendingOrders[i]['Products'][j]['CategoryId'] == 4) {
								if (this.pendingOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages']) {
									tempArr.push({
										'medium': this.pendingOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages'][1].ImageUrl,
										'big': this.pendingOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages'][1].ImageUrl
									})
									this.pendingOrders[i]['Products'][j]['Gallery'] = tempArr
								}
							} else if (this.pendingOrders[i]['Products'][j]['CategoryId'] == 5) {
								if(this.pendingOrders[i]['Products'][j]['Items']['carDetails']) {
									if (this.pendingOrders[i]['Products'][j]['Items']['carDetails']['Images']) {
										tempArr.push({
											'medium': this.pendingOrders[i]['Products'][j]['Items']['carDetails']['Images'][0],
											'big': this.pendingOrders[i]['Products'][j]['Items']['carDetails']['Images'][0]
										})
										this.pendingOrders[i]['Products'][j]['Gallery'] = tempArr
									}
								}
								
							} else if (this.pendingOrders[i]['Products'][j]['CategoryId'] == 6) {
								if (this.pendingOrders[i]['Products'][j]['Items']['packageDetails']['Images']) {
									for (let k = 0; k < this.pendingOrders[i]['Products'][j]['Items']['packageDetails']['Images'].length; k++) {
										tempArr.push({
											'medium': this.pendingOrders[i]['Products'][j]['Items']['packageDetails']['Images'][k],
											'big': this.pendingOrders[i]['Products'][j]['Items']['packageDetails']['Images'][k]
										})
										if (k == this.pendingOrders[i]['Products'][j]['Items']['packageDetails']['Images'].length - 1) {
											this.pendingOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							}
						}
					}
					this.pendingOrders.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt'])
					})

				}
				if (this.cancelledOrders && this.cancelledOrders.length) {
					for (let i = 0; i < this.cancelledOrders.length; i++) {
						for (let j = 0; j < this.cancelledOrders[i]['Products'].length; j++) {
							var tempArr = []
							if (this.cancelledOrders[i]['Products'][j]['CategoryId'] == 1 || this.cancelledOrders[i]['Products'][j]['CategoryId'] == 2 || this.cancelledOrders[i]['Products'][j]['CategoryId'] == 7) {
								if (this.cancelledOrders[i]['Products'][j]['Items'][0]['Images']) {
									for (let k = 0; k < this.cancelledOrders[i]['Products'][j]['Items'][0]['Images'].length; k++) {
										tempArr.push({
											'medium': this.cancelledOrders[i]['Products'][j]['Items'][0]['Images'][k],
											'big': this.cancelledOrders[i]['Products'][j]['Items'][0]['Images'][k]
										})
										if (k == this.cancelledOrders[i]['Products'][j]['Items'][0]['Images'].length - 1) {
											this.cancelledOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							} else if(this.cancelledOrders[i]['Products'][j]['CategoryId'] == 3) {
								if (this.cancelledOrders[i]['Products'][j]['Images']) {
									for (let k = 0; k < this.cancelledOrders[i]['Products'][j]['Images'].length; k++) {
										tempArr.push({
											'medium': this.cancelledOrders[i]['Products'][j]['Images'][k].ImageUrl,
											'big': this.cancelledOrders[i]['Products'][j]['Images'][k].ImageUrl
										})
										if (k == this.cancelledOrders[i]['Products'][j]['Images'].length - 1) {
											this.cancelledOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							} else if (this.cancelledOrders[i]['Products'][j]['CategoryId'] == 4) {
								if (this.cancelledOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages']) {
									tempArr.push({
										'medium': this.cancelledOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages'][1].ImageUrl,
										'big': this.cancelledOrders[i]['Products'][j]['Items']['TicketDetails']['MovieImages'][1].ImageUrl
									})
									this.cancelledOrders[i]['Products'][j]['Gallery'] = tempArr
								}
							} else if (this.cancelledOrders[i]['Products'][j]['CategoryId'] == 5) {
								if(this.cancelledOrders[i]['Products'][j]['Items']['carDetails']) {
									if (this.cancelledOrders[i]['Products'][j]['Items']['carDetails']['Images']) {
										tempArr.push({
											'medium': this.cancelledOrders[i]['Products'][j]['Items']['carDetails']['Images'][0],
											'big': this.cancelledOrders[i]['Products'][j]['Items']['carDetails']['Images'][0]
										})
										this.cancelledOrders[i]['Products'][j]['Gallery'] = tempArr
									}
								}
							} else if (this.cancelledOrders[i]['Products'][j]['CategoryId'] == 6) {
								if (this.cancelledOrders[i]['Products'][j]['Items']['packageDetails']['Images']) {
									for (let k = 0; k < this.cancelledOrders[i]['Products'][j]['Items']['packageDetails']['Images'].length; k++) {
										tempArr.push({
											'medium': this.cancelledOrders[i]['Products'][j]['Items']['packageDetails']['Images'][k],
											'big': this.cancelledOrders[i]['Products'][j]['Items']['packageDetails']['Images'][k]
										})
										if (k == this.cancelledOrders[i]['Products'][j]['Items']['packageDetails']['Images'].length - 1) {
											this.cancelledOrders[i]['Products'][j]['Gallery'] = tempArr
										}
									}
								}
							}
						}
					}
					this.cancelledOrders.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					});

				}
			}
		});
	}

	openDialog(orderObj: any) {
		var obj = {
			dataObj: orderObj,
		}
		const dialogRef = this.dialog.open(TrackorderdialogComponent, {
			width: '300px',
			height: '460px',
			data: obj
		});
		dialogRef.afterClosed().subscribe(result => {
		});
	}

	cancelOrder(orderObj: any) {
		var obj = {
			dataObj: orderObj,
			component: 'orderComponent'
		}
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '500px',
			data: obj
		});
		dialogRef.afterClosed().subscribe(result => {
			this.getOrder();
		})
	}

}
