import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { NagercoilService } from './_services';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showHead: boolean = false;
  public isValidUser:boolean = false;
  public user: object;

  ngOnInit() {
  }

  constructor(private router: Router, private wowService: NgwWowService, private Service: NagercoilService, private meta: Meta) {
    this.meta.addTag({ name: 'description', content: 'Nagercoil’s very own service delivery – Nagercoil.com. We enable customers to order items from their favourite outlets and sit back and relax.' });
    this.meta.addTag({ name: 'keywords', content: 'Food & Drinks, Groceries, Medicines, Travel Services, Tour Packages' });
    router.events.forEach((event) => {
		if (event instanceof NavigationStart) {
			if (event['url'] == '/signup' || event['url'] == '/signin') {
				this.showHead = false;
			}
			else {
				this.showHead = true;
			}
		}
    });
    this.wowService.init();
  }
}
