import { Component, OnInit, HostListener, Inject, Renderer2 } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';
import { Router, ActivatedRoute} from '@angular/router';
import {NagercoilService} from '../_services/nagercoil.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material';
import { StatusdialogComponent } from '../statusdialog/statusdialog.component';
import * as moment from 'moment-timezone';


@Component({
   	selector: 'app-tour-details',
   	templateUrl: './tour-details.component.html',
	styleUrls: ['./tour-details.component.css'],
	providers: [DatePipe]
})

export class TourDetailsComponent implements OnInit {
	public packageDetails: Object[];
	public panelOpenState: boolean = false;
	public isScroll: boolean = false;
	public stickyForm : boolean = false;
	public singleMenu : boolean = false;
	public sectionsArr: Object[];
	public PageLoader: boolean = false;
	public user: object;
	public id: any;
	public code: any;
	public itenerary: any;
	public galleryOptions: NgxGalleryOptions[];
	public galleryImages: NgxGalleryImage[];
	public form: FormGroup;
	public tourDetails: Object[];
	public minDate:any;
	public currentDate = new Date();
	public istMoment: any;
	public IstTimeZone = "Asia/Kolkata";
	public carouselOptions = {
		dots: false,
		autoplayTimeout:3500,
		loop:true,
		autoplayHoverPause:true,
		margin: 25,
		center: true,
		nav: true,
		navText: ["<div class='nav-btn prev-slide'> <i class='material-icons'>    keyboard_arrow_left </i> </div>", "<div class='nav-btn next-slide'><i class='material-icons'>    keyboard_arrow_right </i></div>"],
		responsiveClass: true,
		responsive: {
			0: {
			items: 1,
			loop: true,
			nav: true
			},
			600: {
			items: 1,
			loop: true,
			nav: true
			},
			1000: {
			items: 1,
			loop: true,
			nav: true
			},
			1500: {
			items: 1,
			loop: true,
			nav: true
			}
		}
	}
	

  	constructor(@Inject(DOCUMENT) private document: Document, public dialog: MatDialog, private renderer: Renderer2,private router: Router,private toastr: ToastrService, private route: ActivatedRoute,private Service: NagercoilService, private fb: FormBuilder, private datePipe: DatePipe) {
		this.form = fb.group({
			'packageList': [null, Validators.compose([Validators.required])],
			'date': [null, Validators.compose([Validators.required])],
		});
	}

	ngOnInit() { 
		this.istMoment = moment(this.currentDate).tz(this.IstTimeZone).format("YYYY-MM-DD HH:mm:ss");
		this.sectionsArr = [
            {
                'name': 'GALLERY',
                'id': 'Photos',
                'isSelected': true
            },
            {
                'name': 'OVERVIEW',
                'id': 'Overview',
                'isSelected': false
            },
            {
                'name': 'DAY WISE ITINERARY',
                'id': 'Itinerary',
                'isSelected': false
            },
            {
                'name': 'ADDITIONAL INFO',
                'id': 'Additional',
                'isSelected': false
            }
            
        ]

		this.galleryOptions = [
			{
			  width: '250px',
			  height: '200px',
			  thumbnailsColumns: 4,
			  preview: true,
			  thumbnails: false,
			  previewCloseOnClick: true,
			  previewCloseOnEsc : true,
			  previewKeyboardNavigation : true,
			  imageAutoPlay: false
			}
		];

		this.PageLoader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(!this.user) {
			this.router.navigate(['signin']);
		} else {
			this.code = this.route.snapshot.params.code ? this.route.snapshot.params.code: null;
			this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id: null;
			if(this.id) {
				this.getPackageList();
				let route = 'tour/package/' + this.id;
				this.Service.get(route, this.user['token']).then(response => {
					this.PageLoader = false;
					this.renderer.removeClass(this.document.body, 'bdovflw_hi');					
					if(response.data) { 
						if(response.data.TouristPlace.Code == this.code) {
							this.packageDetails = response.data;
							this.form.patchValue({
								'packageList' : this.packageDetails['Id'],
							});
							this.itenerary = [];
							for(let i=0; i<this.packageDetails['Features'].length; i++) {
								if(this.packageDetails['Features'][i]['Images'].length) {
									this.packageDetails['Features'][i]['Gallery'] = []
									for(let j=0; j<this.packageDetails['Features'][i]['Images'].length; j++) {
										this.packageDetails['Features'][i]['Gallery'].push({
											'medium' : this.packageDetails['Features'][i]['Images'][j],
											'big' : this.packageDetails['Features'][i]['Images'][j]
										});
									}
								}
								let Index = this.itenerary.map(function(x) {
									return x['Day'];
								}).indexOf(this.packageDetails['Features'][i]['Day']);

								if(Index == -1) {
									this.itenerary.push({
										'Day': this.packageDetails['Features'][i]['Day'],
										'Features':[this.packageDetails['Features'][i]],
									})
								} else {
									this.itenerary[Index].Features.push(this.packageDetails['Features'][i])
								}
							}
						} else {
							this.router.navigate(['**']);
						}
					} 
				});
			}
		}
	}

    @HostListener('window:scroll', ['$event'])

	onWindowScroll(e) {
		if (window.pageYOffset > 50) {
		this.isScroll = true;
		this.stickyForm = true;
		} else {
		this.isScroll = false;
		this.stickyForm = false;
		}
	}



	scroll(id: any, index: any) {
        let selectedIndex = this.sectionsArr.findIndex((obj => obj['isSelected'] == true));
        this.sectionsArr[selectedIndex]['isSelected'] = false
        this.sectionsArr[index]['isSelected'] = true
        let el = document.getElementById(id);
        if(el) {
            var topOfElement = document.getElementById(id).offsetTop - 160;
            if(id == 'Overview' && topOfElement == 400) {
                window.scroll({ top: 266, behavior: "smooth" });
            } else {
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }
        }
        
	}
	
	getPackageList() {
		let route = 'tours';
		this.Service.get(route, this.user['token']).then(response => {
			if(response.data) {
				this.tourDetails = response.data;
				this.minDate = new Date(this.istMoment);
			} 
		});
	}

	onSubmit() {
		let route = "v2/product";
    	let token = this.user['token'];
		let params = {};
		var items = {
			'packageId' :this.form.value.packageList,
			'dateTime' : this.datePipe.transform(this.form.value.date, 'yyyy-MM-dd 00:00:00'),
			'name' : this.user['name'].trim(),
			'email' : this.user['email'].trim(),
			'mobile' : parseInt(this.user['phone'].trim()),
		};
		params['items'] = items;
		params['category'] = 6;
		this.Service.post(route, token, params).then(response => {
			if(response.status) {
				const dialogRef = this.dialog.open(StatusdialogComponent, {
					width: '500px',
					height: 'auto',
					disableClose: true,
					data : 'tourComponent'
				});
				dialogRef.afterClosed().subscribe(result => {
					this.form.reset();
					this.router.navigate(['basket']);
				});
			} else {
				this.toastr.error(response.messages[0]);
			}
		});
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons/tour_icon/placeholder-tour-image.png";
	}
  
}
