import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NagercoilService } from "../_services";
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-computer-list',
  templateUrl: './computer-list.component.html',
  styleUrls: ['./computer-list.component.css'],
  providers: [DatePipe],
})
export class ComputerListComponent implements OnInit {
  public id: any;
  public user: object;
  public storeDetails: Object;
  public foodType: Object[];
  public foodDetails: Object[];
  public Object = Object;
  public cartItems: any;
  public allBasket: any;
  public stickyItems: boolean = false;
  public PageLoader: boolean = false;
  public searchTerm: any;
  public defaultRestaurantMenu: any;
  public hideSearchResults: boolean;
  public searchForm: FormGroup;
  public computers: any;
  public searchResults: any;
  public currentMoment: any;
  public istMoment = moment();
  public IstTimeZone = "Asia/Kolkata";
  public timeFormat = "HH:mm";

  constructor(private route: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder, private router: Router, private Service: NagercoilService, private datePipe: DatePipe) {
    this.searchForm = this.fb.group({
      term: [null]
    });
  }

  ngOnInit() {
    this.currentMoment = moment.tz(this.IstTimeZone);
    this.PageLoader = true;
    this.user = JSON.parse(localStorage.getItem("userCredential"));
    this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : null;
    if (!this.user) {
      this.router.navigate(["signin"]);
    } else {
      this.getStoreDetail();
    }
    this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val.term) {
        this.hideSearchResults = false;
        let term = val.term;
        this.searchTerm = term;
        term = term.toLowerCase();
        term = term.trim();
        this.computers = [];
        for (let x in this.storeDetails['Menus']) {
          let tempDish = this.storeDetails['Menus'][x].filter(function (e: any) { return e.Name.toLowerCase().search(term) != -1 });
          this.computers.push(tempDish);
        }
        this.searchResults = this.computers.flat(1);
      } else {
        this.computers = [];
        this.searchTerm = "";
        this.computers = [];
        this.searchResults = [];
        this.hideSearchResults = true;
      }
    });
  }

  getStoreDetail() {
    let route = "computers/" + this.id;
    this.Service.get(route, this.user["token"]).then((response) => {
      this.PageLoader = false;
      if (response) {
        this.storeDetails = response;
        if (this.storeDetails['WorkingHours'] && this.storeDetails['WorkingHours'].length) {
          this.storeDetails['TodaysWorkingHours'] = [];
          for (let i = 0; i < this.storeDetails['WorkingHours'].length; i++) {
            let workingHours = {
              fromTime: moment(this.storeDetails['WorkingHours'][i]['From'], 'hh:mm').format('hh:mm a'),
              toTime: moment(this.storeDetails['WorkingHours'][i]['To'], 'hh:mm').format('hh:mm a'),
              fromEpoch: moment.tz(this.storeDetails['WorkingHours'][i]['From'], this.timeFormat, this.IstTimeZone),
              toEpoch: moment.tz(this.storeDetails['WorkingHours'][i]['To'], this.timeFormat, this.IstTimeZone)
            };
            this.storeDetails['TodaysWorkingHours'].push(workingHours);
          }
        } else {
          this.storeDetails['TodaysWorkingHours'] = [];
        }

        if (this.storeDetails['BreakHours'] && this.storeDetails['BreakHours'].length) {
          this.storeDetails['TodaysBreakHours'] = [];
          for (let i = 0; i < this.storeDetails['BreakHours'].length; i++) {
            let breakHours = {
              fromTime: moment(this.storeDetails['BreakHours'][i]['From'], 'hh:mm').format('hh:mm a'),
              toTime: moment(this.storeDetails['BreakHours'][i]['To'], 'hh:mm').format('hh:mm a'),
              fromEpoch: moment.tz(this.storeDetails['BreakHours'][i]['From'], this.timeFormat, this.IstTimeZone),
              toEpoch: moment.tz(this.storeDetails['BreakHours'][i]['To'], this.timeFormat, this.IstTimeZone)
            };
            this.storeDetails['TodaysBreakHours'].push(breakHours);
          }
        } else {
          this.storeDetails['TodaysBreakHours'] = [];
        }

        // if (this.storeDetails['Status'] && this.storeDetails['Status'] == 'Opened') {
          // if (this.storeDetails['TodaysWorkingHours'] && this.storeDetails['TodaysWorkingHours'].length) {
          //   for (let j = 0; j < this.storeDetails['TodaysWorkingHours'].length; j++) {
          //     if (this.currentMoment.isBetween(this.storeDetails['TodaysWorkingHours'][j]['fromEpoch'], this.storeDetails['TodaysWorkingHours'][j]['toEpoch'])) {
          //       this.storeDetails['isOffline'] = false;
          //     } else {
          //       if (this.storeDetails['isOffline'] == false) {
          //       } else {
          //         this.storeDetails['isOffline'] = true;
          //       }
          //     }
          //   }
          // } else {
          //   this.storeDetails['isOffline'] = true;
          // }

          // if (this.storeDetails['TodaysBreakHours'] && this.storeDetails['TodaysBreakHours'].length) {
          //   for (let j = 0; j < this.storeDetails['TodaysBreakHours'].length; j++) {
          //     if (this.currentMoment.isBetween(this.storeDetails['TodaysBreakHours'][j]['fromEpoch'], this.storeDetails['TodaysBreakHours'][j]['toEpoch'])) {
          //       this.storeDetails['isBreakTime'] = true;
          //     } else {
          //       this.storeDetails['isBreakTime'] = false;
          //     }
          //   }
          // } else {
          //   this.storeDetails['isBreakTime'] = false;
          // }

        // } else {
        //   this.storeDetails['isClosed'] = true;
        // }

        // if (this.storeDetails['isOffline'] && !this.storeDetails['isBreakTime']) {
        //   this.toastr.warning('Store is currently closed for online ordering!');
        // } else if (this.storeDetails['isBreakTime'] && !this.storeDetails['isOffline']) {
        //   this.toastr.warning('Store is currently unserviceable!');
        // } else if (this.storeDetails['isBreakTime'] && this.storeDetails['isOffline']) {
        //   this.toastr.warning('Store is currently unserviceable!');
        // } else if (this.storeDetails['isClosed']) {
        //   this.toastr.warning('Store is currently closed');
        // }

        if (this.searchTerm) {
          this.searchForm.patchValue({
            term: this.searchTerm
          });
        }

        this.cartItems = [];

        if (this.storeDetails['Id']) {
          let foodTypeArr = Object.keys(this.storeDetails["Menus"]);
          this.foodType = [];
          if (foodTypeArr && foodTypeArr.length) {
            for (let i = 0; i < foodTypeArr.length; i++) {
              this.foodType.push({
                name: foodTypeArr[i],
                isClicked: false
              });
            }
            this.foodType[0]['isClicked'] = true;
          }

          for (let x in this.storeDetails['Menus']) {
            for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
              this.storeDetails['Menus'][x][i]['isAdded'] = false;

              if (this.storeDetails['Menus'][x][i]['Quantity']) {
                this.storeDetails['Menus'][x][i]['totalPrice'] = parseInt(this.storeDetails['Menus'][x][i]['Quantity']) * this.storeDetails['Menus'][x][i]['Price'];
                this.cartItems.push(this.storeDetails['Menus'][x][i]);
              }
            }
          }
        } else {
          this.router.navigate(['**']);
        }
      }
    });
    this.getBasket();
  }

  getBasket() {
    let route = 'v2/product';
    this.Service.get(route, this.user['token']).then(response => {
      if (response.data) {
        this.allBasket = response.data.filter(function (product) {
          return product['ForLater'] == 0
        });
        this.Service.changeCount(this.allBasket.length)
      }
    });
  }

  incrementQty(item) {
    for (let x in this.storeDetails['Menus']) {
      for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
        if (item.Id == this.storeDetails['Menus'][x][i]['Id']) {
          this.storeDetails['Menus'][x][i]['isAdded'] = true;
        } else {
          this.storeDetails['Menus'][x][i]['isAdded'] = false;
        }
      }
    }
    if (!item.Quantity) {
      let route = 'v2/product';
      let params = {
        shop_name: this.storeDetails['Name'],
        items: [{
          itemName: item.Name,
          itemOrder: 1,
          quantity: '1',
          action: "add",
          category: 7,
        }],
        category: '7'
      }
      this.Service.post(route, this.user['token'], params).then(response => {
        if (response.status) {
          this.getStoreDetail();
        } else {
          for (let x in this.storeDetails['Menus']) {
            for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
              this.storeDetails['Menus'][x][i]['isAdded'] = false;
            }
          }
          this.toastr.error('Please try after sometime.', 'Something went wrong!');
        }
      });
    } else if (item.Quantity) {
      let route = 'v2/product/' + item.BasketId + '?_method=PUT';
      let params = {
        shop_name: this.storeDetails['Name'],
        items: [{
          id: item.ItemId,
          itemOrder: 1,
          itemName: item.Name,
          quantity: (parseInt(item.Quantity) + 1).toString(),
          action: "update",
          category: 7,
        }],
        category: '7'
      }
      this.Service.post(route, this.user['token'], params).then(response => {
        if (response.status) {
          this.getStoreDetail();
        } else {
          for (let x in this.storeDetails['Menus']) {
            for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
              this.storeDetails['Menus'][x][i]['isAdded'] = false;
            }
          }
          this.toastr.error('Please try after sometime.', 'Something went wrong!');
        }
      });
    }
  }

  decrementQty(item) {
    for (let x in this.storeDetails['Menus']) {
      for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
        if (item.Id == this.storeDetails['Menus'][x][i]['Id']) {
          this.storeDetails['Menus'][x][i]['isAdded'] = true;
        } else {
          this.storeDetails['Menus'][x][i]['isAdded'] = false;
        }
      }
    }
    if (item.Quantity == '1') {
      let route = 'v2/product/' + item.BasketId;
      this.Service.delete(route, this.user['token']).then(response => {
        if (response.status) {
          this.getStoreDetail();
        } else {
          for (let x in this.storeDetails['Menus']) {
            for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
              this.storeDetails['Menus'][x][i]['isAdded'] = false;
            }
          }
          this.toastr.error('Please try after sometime.', 'Something went wrong!');
        }
      });
    } else if (item.Quantity > 1) {
      let route = 'v2/product/' + item.BasketId + '?_method=PUT';
      let params = {
        shop_name: this.storeDetails['Name'],
        items: [{
          id: item.ItemId,
          itemOrder: 1,
          itemName: item.Name,
          quantity: (parseInt(item.Quantity) - 1).toString(),
          action: "update",
          category: 7,
        }],
        category: '7'
      }
      this.Service.post(route, this.user['token'], params).then(response => {
        if (response.status) {
          this.getStoreDetail();
        } else {
          for (let x in this.storeDetails['Menus']) {
            for (let i = 0; i < this.storeDetails['Menus'][x].length; i++) {
              this.storeDetails['Menus'][x][i]['isAdded'] = false;
            }
          }
          this.toastr.error('Please try after sometime.', 'Something went wrong!');
        }
      });
    }
  }

  scroll(key: any, index: any) {
    var elements = document.getElementById(key.name);
    if (elements) {
      var topOfElement = document.getElementById(key.name).offsetTop;
      if (index == 0 && topOfElement == 90) {
        window.scroll({ top: 300, behavior: "smooth" });
      } else {
        window.scroll({ top: topOfElement + 90, behavior: "smooth" });
        // elements.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    }

    for (let i = 0; i < this.foodType.length; i++) {
      if (key.name == this.foodType[i]['name']) {
        this.foodType[i]['isClicked'] = true;
      } else {
        this.foodType[i]['isClicked'] = false;
      }
    }
  }

  @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
    if (window.pageYOffset > 300) {
      this.stickyItems = true;
    } else {
      this.stickyItems = false;
    }
  }

  imageErrorHandler(event) {
    event.target.src = "https://cdn.dribbble.com/users/1691216/screenshots/5348419/shopping_bag.jpg";
  }
}
