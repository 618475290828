import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NagercoilService } from '../_services';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-tour',
	templateUrl: './tour.component.html',
	styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
	public control = new FormControl();
	public Places: any;
	public filteredPlaces: Observable<any>;
	public user: object;
	public tourDetails: object[];
	public bannerImages = [];
	public pageLoader: boolean = false;
	public carouselOptions = {
		dots: false,
		autoplayTimeout: 3500,
		loop: true,
		autoplayHoverPause: true,
		margin: 25,
		left: true,
		nav: true,
		navText: ["<div class='nav-btn prev-slide'> <i class='material-icons'>	keyboard_arrow_left </i> </div>", "<div class='nav-btn next-slide'><i class='material-icons'>	keyboard_arrow_right </i></div>"],
		responsiveClass: true,
		responsive: {
			0: {
				items: 1,
				nav: true
			},
			600: {
				items: 1,
				nav: true
			},
			1000: {
				items: 3,
				nav: true
			},
			1500: {
				items: 3,
			}
		}
	}

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.filteredPlaces = this.control.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value))
		);
		this.pageLoader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (!this.user) {
			this.router.navigate(['signin']);
		}
		this.getTourDetails();
	}

	getTourDetails() {
		if (this.user) {
			let route = "tours";
			this.Service.get(route, this.user['token']).then(response => {
				this.pageLoader = false;
				this.renderer.removeClass(this.document.body, 'bdovflw_hi');
				if (response.data) {

					this.tourDetails = response.data;
					this.Places = JSON.parse(JSON.stringify(response.data))
					for (let i = 0; i < this.tourDetails.length; i++) {
						this.bannerImages.push({
							'Image': this.tourDetails[i]['Images'][0],
							'id': this.tourDetails[i]['Id']
						});
					}
				}
			});
		}
	}

	private _filter(value: any): any {
		const filterValue = this._normalizeValue(value);
		return this.Places.filter(street => this._normalizeValue(street.Name).includes(filterValue));
	}

	private _normalizeValue(value: any): string {
		return value.toLowerCase().replace(/\s/g, '');
	}
	selectedOption(place) {
		this.router.navigate(['tour/' + place.Code]);
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons/tour_icon/placeholder-tour-image.png";
	}

}
