import { Injectable } from '@angular/core';
import { ApiService } from '../_api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NagercoilService {

    private countSource = new BehaviorSubject(0);
    basketCount = this.countSource.asObservable();
    changeCount(count: number) {
        this.countSource.next(count)
    }

    private userSource = new BehaviorSubject({});
    userData = this.userSource.asObservable();
    changeUser(user: any) {
        this.userSource.next(user)
    }

    constructor(public API: ApiService) {}
    
    auth(params: any, route: any) {
        
        return new Promise < any > ((resolve, reject) => {

            this.API.auth(params, route).subscribe(response => {
                resolve(response);
            });

        });

    }

    
    get(route: any, token: any) {

        return new Promise < any > ((resolve, reject) => {

            this.API.get(route, token).subscribe(response => {
                resolve(response);
            });

        });
    }

    put(route: any, token: any, params: any) {

        return new Promise < any > ((resolve, reject) => {

            this.API.put(route, token ,params ).subscribe(response => {
                resolve(response);
            });

        });
    }

    post(route: any, token: any, params: any) {
        return new Promise < any > ((resolve, reject) => {

            this.API.post(route, token, params).subscribe(response => {
                resolve(response);
            });

        }); 
    }

    delete(route: any, token: any) {
        return new Promise < any > ((resolve, reject) => {

            this.API.delete(route, token).subscribe(response => {
                resolve(response);
            });

        });
    }

    formData(route: any, token: any, params: any) {
        return new Promise < any > ((resolve, reject) => {

            this.API.formData(route, token, params).subscribe(response => {
                resolve(response);
            });

        });
    }


}