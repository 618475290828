import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import {NagercoilService} from '../_services/nagercoil.service';
import { Router } from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-addressdailog',
  templateUrl: './addressdailog.component.html',
  styleUrls: ['./addressdailog.component.css']
})


export class AddressdailogComponent implements OnInit {
    public lat: any;
	public lng: any;
	public mode = 'form';
	public addressForm: FormGroup;
	public errorMsg : boolean = false;
	public user: object;
	public loader: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,private toastr: ToastrService, private fb: FormBuilder, private router: Router, private Service: NagercoilService, public dialogRef: MatDialogRef<AddressdailogComponent>) {
		if(this.data && this.data.latitude && this.data.longitude) {
			this.lat = this.data.latitude;
			this.lng = this.data.longitude;
		} else {
			this.lat = 8.1833,
			this.lng = 77.41
			if (navigator) {
				navigator.geolocation.getCurrentPosition( pos => {
					this.lng = +pos.coords.longitude;
					this.lat = +pos.coords.latitude;
				});
			}
		}
		
		
	}

	markerDragEnd($event: MouseEvent) {
		this.lat = $event['coords'].lat;
		this.lng = $event['coords'].lng;
	}

	saveLocation() {
		var concat  = this.lat.toFixed(2).toString().concat(' , ');
		var latLng = concat.concat(this.lng.toFixed(2).toString());
		this.addressForm.controls['latLng'].setValue(latLng);
		this.mode = 'form'
	}

    ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		this.addressForm = this.fb.group({
			name: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: [null, Validators.compose([Validators.required])],
			country: ["India", Validators.compose([Validators.required])],
			add1: [null, Validators.compose([Validators.required])],
			add2: [null, ''],
			city: ["Nagercoil", Validators.compose([Validators.required])],
			state: ['Tamil Nadu', Validators.compose([Validators.required])],
			zip: [null, Validators.compose([Validators.required])],
			latLng: [null, ''],
			isDefault: [null, '']
		});

		if(this.data) {
			var latLng = '';
			if(this.data.latitude && this.data.longitude) {
				let concat  = this.data.latitude.toString().concat(' , ');
				latLng = concat.concat(this.data.longitude.toString())
			}
			this.addressForm.setValue({
				name: this.data.name,
				email: this.data.email,
				phone: this.data.phone,
				country: this.data.country,
				add1: this.data.address1,
				add2: this.data.address2,
				city: this.data.city,
				state: this.data.state,
				zip: this.data.postcode,
				latLng: latLng,
				isDefault: this.data.is_primary ? true: false
			});
		}
	}
	
	addAddress() {
		if (this.addressForm.invalid) {
            return;
		}

		this.loader = true;
		let token = this.user['token'];
		let params = {
			"name": this.addressForm.value.name.trim(),
			"email": this.addressForm.value.email.trim(),
			"phone": parseInt(this.addressForm.value.phone),
			"add1": this.addressForm.value.add1.trim(),
			"city": this.addressForm.value.city.trim(),
			"state": this.addressForm.value.state.trim(),
			"country": this.addressForm.value.country.trim(),
			"zip": this.addressForm.value.zip.toString(),
			"is_primary": this.addressForm.value.isDefault ? 1 : 0
		}
		if(this.addressForm.value.add2) {
			params['add2'] = this.addressForm.value.add2.trim();
		}
		if(this.addressForm.value.latLng) {
			if(this.lat && this.lng) {
				params['lat'] = this.lat;
				params['long'] = this.lng;
			}
		}
		if(this.data) {
			let route = 'address/' + this.data.addressId;
			this.Service.put(route, token, params).then(response => {
				this.loader = false;
				if(response.status) {
					this.toastr.success('Address Updated Successfully', 'Success');
					this.dialogRef.close(true);
				} else {
					this.toastr.error(response.messages[0]);
				}
			});
		} else {
			let route = 'address';
			this.Service.post(route, token, params).then(response => {
				this.loader = false;
				if(response.status) {
					this.toastr.success('Address added Successfully', 'Success');
					this.dialogRef.close(true);
				} else {
					this.toastr.error(response.messages[0]);
				}
			});
		}	
	}
}
