import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sessionexpired',
  templateUrl: './sessionexpired.component.html',
  styleUrls: ['./sessionexpired.component.css']
})
export class SessionexpiredComponent implements OnInit {

  public user: object;
  constructor(private router: Router, public dialogRef: MatDialogRef<SessionexpiredComponent>) { }

  ngOnInit() {					
  }

  confirm() {
    this.dialogRef.close()
    this.router.navigate(['/signin']);
  }
}
