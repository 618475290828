import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NagercoilService } from '../_services/nagercoil.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
	public OTPForm: FormGroup;
	public registerSection: boolean = true;
	public signUpForm: FormGroup;
	public errorMsg : boolean = false;
	public user: object;
	public errorData: string[];
	public otpErrorMsg: boolean = false;
	public otpSuccess:boolean = false;
	public loader: boolean = false;
	public otpStatus: boolean = false;
	constructor(private fb: FormBuilder, private router: Router, private Service: NagercoilService) { }
	  
	ngOnInit() {
		this.signUpForm = this.fb.group({
			password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
			email: [null, Validators.compose([Validators.required, Validators.email])],
			name: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			phoneNumber: [null, Validators.compose([Validators.required])]
		});

		this.OTPForm = this.fb.group({
			otp: [null, Validators.compose([Validators.required])],
		});

		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(this.user) {
			this.router.navigate(['/']);
		}
	}

	SignUp() {
		if (this.signUpForm.invalid) {
            return;
		}
		this.loader = true;
		let params = {
			'email':this.signUpForm.value.email.trim(),
			'password':this.signUpForm.value.password.trim(),
			'name':this.signUpForm.value.name.trim(),
			'username':parseInt(this.signUpForm.value.phoneNumber)
			,
			'role_id': 7
		}
		let route = 'register'

		this.Service.auth(params, route).then(resp => {
			if(resp.access_token) {
				this.registerSection = false;
				this.loader = false;
				localStorage.setItem('tempToken', JSON.stringify(resp.access_token));
			} else {
				if(resp.messages.length) {
					this.errorData = resp.messages;
					this.loader = false;
					this.errorMsg = true;
				}
				else {
					this.errorData =['Something went wrong! Please try after sometime'];
					this.loader = false;
					this.errorMsg = true;
				}
			}	
		});
	}

	OTPVerfication() {
		if (this.OTPForm.invalid) {
            return;
		}
		this.loader = true;
		let params = {
			'verification_code': parseInt(this.OTPForm.value.otp)
		}
		let token = JSON.parse(localStorage.getItem('tempToken'));
		let route = 'mobile/verify' 
		this.Service.put(route, token, params).then(response => {
			if(response.status) {
				this.Service.get('user', token).then(response => {
					if(response.data) {
						response.data.token = JSON.parse(localStorage.getItem('tempToken'));
						localStorage.setItem('userCredential', null);
						localStorage.setItem('userCredential', JSON.stringify(response.data));
						this.router.navigate(['/']);
					} else {
						localStorage.setItem('userCredential', null);
						this.loader = false;
						this.otpSuccess = false;
						this.errorMsg = true;
					}
				});
			} else {
				if(response.messages.length) {
					this.loader = false;
					this.otpSuccess = false;
					this.errorData = response.messages;
					this.otpErrorMsg = true;
				}
				else {
					this.errorData =['Something went wrong! Please try after sometime'];
					this.loader = false;
					this.otpSuccess = false;
					this.otpErrorMsg = true;
				}
			}
		});
	}
	generateOtp() {
		this.otpStatus = true;
		let route = 'mobile/verify/resend';
		let token = JSON.parse(localStorage.getItem('tempToken'));
		this.Service.get(route, token).then(response => {
			if(response.status) {
				if(response.messages.length) {
					this.errorData = response.messages;
					this.otpErrorMsg = true;
					if(this.errorData[0]=="Verification code has been sent"){
						this.otpStatus = false;
						this.otpSuccess = true;
					}
				} else {
					this.otpStatus = false;
					this.errorData =['Something went wrong! Please try after sometime'];
					this.otpErrorMsg = true;
					this.otpSuccess = false;
				}
			}
			else {
				this.otpStatus = false;
				this.errorData =['Something went wrong! Please try after sometime'];
				this.otpErrorMsg = true;
				this.otpSuccess = false;
			}
		});
		
	}
}
