import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NagercoilService } from '../_services/nagercoil.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import alasql from 'alasql';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-resturant',
  templateUrl: './resturant.component.html',
  styleUrls: ['./resturant.component.css'],
  providers: [DatePipe],
})

export class ResturantComponent implements OnInit {
  public form: FormGroup;
  public user: object;
  public restaurants: any;
  public id: any;
  public resturantDetails: Object[];
  public minDate: any;
  public PageLoader: boolean = false;
  public searchForm: FormGroup;
  public allRestaurants: any;
  public config: any;
  public currentMoment: any;
  public istMoment = moment();
  public IstTimeZone = "Asia/Kolkata";
  public timeFormat = "HH:mm";

  constructor(private route: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder, private router: Router, private Service: NagercoilService, private datePipe: DatePipe) {
    this.searchForm = this.fb.group({
      term: [null]
    });

  }

  ngOnInit() {
    this.currentMoment = moment.tz(this.IstTimeZone);
    this.PageLoader = true;
    this.user = JSON.parse(localStorage.getItem('userCredential'));
    if (!this.user) {
      this.router.navigate(['signin']);
    }
    this.getResturant();

    this.searchForm.valueChanges.pipe().subscribe(val => {
      if (val.term) {
        this.restaurants = this.allRestaurants;
        let term = val.term;
        term = term.toLowerCase();
        term = term.trim();
        var res = alasql("SELECT * FROM restaurants WHERE Name LIKE '%" + term + "%' OR Address LIKE '%" + term + "%' OR Description LIKE '%" + term + "%'");
        this.restaurants = res;
      } else {
        this.restaurants = this.allRestaurants;
      }
    });
  }

  getResturant() {
    let route = 'restaurants';
    this.Service.get(route, this.user['token']).then(response => {
      this.PageLoader = false;
      if (response) {
        this.restaurants = response;
        if (this.restaurants && this.restaurants.length) {
          for (let i = 0; i < this.restaurants.length; i++) {
            if (this.restaurants[i]['WorkingHours'] && this.restaurants[i]['WorkingHours'].length) {
              this.restaurants[i]['TodaysWorkingHours'] = [];
              for (let j = 0; j < this.restaurants[i]['WorkingHours'].length; j++) {
                let workingHours = {
                  fromTime: moment(this.restaurants[i]['WorkingHours'][j]['From'], 'hh:mm').format('hh:mm a'),
                  toTime: moment(this.restaurants[i]['WorkingHours'][j]['To'], 'hh:mm').format('hh:mm a'),
                  fromEpoch: moment.tz(this.restaurants[i]['WorkingHours'][j]['From'], this.timeFormat, this.IstTimeZone),
                  toEpoch: moment.tz(this.restaurants[i]['WorkingHours'][j]['To'], this.timeFormat, this.IstTimeZone)
                };
                this.restaurants[i]['TodaysWorkingHours'].push(workingHours);
              }
            } else {
              this.restaurants[i]['TodaysWorkingHours'] = [];
            }

            if (this.restaurants[i]['BreakHours'] && this.restaurants[i]['BreakHours'].length) {
              this.restaurants[i]['TodaysBreakHours'] = [];
              for (let j = 0; j < this.restaurants[i]['BreakHours'].length; j++) {
                let breakHours = {
                  fromTime: moment(this.restaurants[i]['BreakHours'][j]['From'], 'hh:mm').format('hh:mm a'),
                  toTime: moment(this.restaurants[i]['BreakHours'][j]['To'], 'hh:mm').format('hh:mm a'),
                  fromEpoch: moment.tz(this.restaurants[i]['BreakHours'][j]['From'], this.timeFormat, this.IstTimeZone),
                  toEpoch: moment.tz(this.restaurants[i]['BreakHours'][j]['To'], this.timeFormat, this.IstTimeZone)
                };
                this.restaurants[i]['TodaysBreakHours'].push(breakHours);
              }
            } else {
              this.restaurants[i]['TodaysBreakHours'] = [];
            }

            if (this.restaurants[i]['Status'] && this.restaurants[i]['Status'] == 'Opened') {
              if (this.restaurants[i]['TodaysWorkingHours'] && this.restaurants[i]['TodaysWorkingHours'].length) {
                for (let j = 0; j < this.restaurants[i]['TodaysWorkingHours'].length; j++) {
                  if (this.currentMoment.isBetween(this.restaurants[i]['TodaysWorkingHours'][j]['fromEpoch'], this.restaurants[i]['TodaysWorkingHours'][j]['toEpoch'])) {
                    this.restaurants[i]['isOffline'] = false;
                  } else {
                    if (this.restaurants[i]['isOffline'] == false) {
                    } else {
                      this.restaurants[i]['isOffline'] = true;
                    }
                  }
                }
              } else {
                this.restaurants[i]['isOffline'] = true;
              }

              if (this.restaurants[i]['TodaysBreakHours'] && this.restaurants[i]['TodaysBreakHours'].length) {
                for (let j = 0; j < this.restaurants[i]['TodaysBreakHours'].length; j++) {
                  if (this.currentMoment.isBetween(this.restaurants[i]['TodaysBreakHours'][j]['fromEpoch'], this.restaurants[i]['TodaysBreakHours'][j]['toEpoch'])) {
                    this.restaurants[i]['isBreakTime'] = true;
                  } else {
                    this.restaurants[i]['isBreakTime'] = false;
                  }
                }
              } else {
                this.restaurants[i]['isBreakTime'] = false;
              }

            } else {
              this.restaurants[i]['isClosed'] = true;
            }

          }
          alasql('CREATE TABLE IF NOT EXISTS restaurants');
          alasql['tables'].restaurants.data = this.restaurants;
          this.allRestaurants = JSON.parse(JSON.stringify(this.restaurants));
        } else {
          this.restaurants = [];
          this.allRestaurants = [];
        }
      } else {
        this.restaurants = [];
        this.allRestaurants = [];
      }
    });
  }

  imageErrorHandler(event) {
    event.target.src = "../../assets/images/no-image.jpg";
  }

  // timerFunction(event) {
  //   // event.stopPropagation();
  //   // event.preventDefault();
  //   console.log(event);
  // }

}
