import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-trackorderdialog',
  templateUrl: './trackorderdialog.component.html',
  styleUrls: ['./trackorderdialog.component.css']
})
export class TrackorderdialogComponent implements OnInit {

  public user: object;
  public isPending:boolean = false;
  public isConfirmed: boolean = false;
  public isInProgess:boolean = false;
  public isOnTheWay:boolean = false;
  public isDelivered:boolean = false;
  constructor( public dialogRef: MatDialogRef<TrackorderdialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userCredential'));
    if(this.data.dataObj.OrderStatus == 'pending') {
      this.isPending = true;
    }
    if(this.data.dataObj.OrderStatus == 'confirmed') {
      this.isConfirmed = true;
    }
    if(this.data.dataObj.OrderStatus == 'in_progress') {
      this.isPending = true;
      this.isInProgess = true;
    }
    if(this.data.dataObj.OrderStatus == 'on the way' || this.data.dataObj.OrderStatus == 'On the way') {
      this.isPending = true;
      this.isInProgess = true;
      this.isOnTheWay = true;
    }
    if(this.data.dataObj.OrderStatus == 'delivered') {
      this.isPending = true;
      this.isInProgess = true;
      this.isOnTheWay = true;
      this.isDelivered = true;
    }
  }

}
