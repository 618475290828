import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-statusdialog',
  templateUrl: './statusdialog.component.html',
  styleUrls: ['./statusdialog.component.css']
})

export class StatusdialogComponent implements OnInit {
  public user: object;
  public tourComponent: boolean = false;
  public travelComponent: boolean = false;

  constructor(public dialogRef: MatDialogRef<StatusdialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userCredential'));
    if(this.data == "tourComponent") {
      this.tourComponent = true;
    } else if(this.data == "travelComponent") {
      this.travelComponent = true;
    } 
  }

}
