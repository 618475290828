import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { ActivatedRoute, Router } from '@angular/router';
import { NagercoilService } from '../_services';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-tour-place',
  templateUrl: './tour-place.component.html',
  styleUrls: ['./tour-place.component.css'],
  animations:[
    trigger('fade', [
      transition('void => *', [style({ opacity: 0.5 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0.5 }))]),
    ])
  ]
})
export class TourPlaceComponent implements OnInit {

	public user: object;
	public placeCode: any;
	public placeDetails:object;
	public pageLoader: boolean = false;
	public carouselOptions = {
	dots: false,
	autoplayTimeout:3500,
	loop:true,
	autoplayHoverPause:true,
	margin: 25,
	center: true,
	nav: true,
	navText: ["<div class='nav-btn prev-slide'> <i class='material-icons'>    keyboard_arrow_left </i> </div>", "<div class='nav-btn next-slide'><i class='material-icons'>    keyboard_arrow_right </i></div>"],
	responsiveClass: true,
	responsive: {
		0: {
		items: 1,
		loop: true,
		nav: true
		},
		600: {
		items: 1,
		loop: true,
		nav: true
		},
		1000: {
		items: 1,
		loop: true
		},
		1500: {
		items: 1,
		loop: true
		}
	}
    }
   
	constructor(@Inject(DOCUMENT) private document: Document,private renderer: Renderer2, private route: ActivatedRoute ,private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.pageLoader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if(!this.user) {
				this.router.navigate(['signin']);
			} else {
			this.placeCode = this.route.snapshot.params.code ? this.route.snapshot.params.code: null;
			if(this.placeCode) {
			let route = 'tours/' +this.placeCode;
			this.Service.get(route, this.user['token']).then(response => {
				this.pageLoader = false;
				this.renderer.removeClass(this.document.body, 'bdovflw_hi');
				if(response.data) {
				this.placeDetails = response.data[0];
				} 
				if(!response.data.length) {
					this.router.navigate(['**']);
				}
			}); 
			}
		}
	}

	imageErrorHandler(event) {
		event.target.src = "../../assets/images/icons/tour_icon/placeholder-tour-image.png";
	}

}
