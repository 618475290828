import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FoodComponent } from './food/food.component';
import { GroceryComponent } from './grocery/grocery.component';
import { MedicineComponent } from './medicine/medicine.component';
import { MovieComponent } from './movie/movie.component';
import { TravelComponent } from './travel/travel.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgwWowModule } from 'ngx-wow';
import { SliderModule } from 'angular-image-slider';
import { OwlModule } from 'ngx-owl-carousel';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlFormFieldModule, OwlInputModule,OwlSelectModule } from 'owl-ng';
import { 
  MatAutocompleteModule,
  MatInputModule,
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule, 
  MatCardModule,
  MatTabsModule,
  MatSelectModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatDialogModule, 
  MatFormFieldModule, MatMenuModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TourDetailsComponent } from './tour-details/tour-details.component';
import { MovieDetailsComponent } from './movie-details/movie-details.component';
import { BasketComponent } from './basket/basket.component';
import { ProfileComponent } from './profile/profile.component';
import { AddressdailogComponent } from './addressdailog/addressdailog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { OrdersComponent } from './orders/orders.component';
import { ContactComponent } from './contact/contact.component';
import { StepperdailogComponent } from './stepperdailog/stepperdailog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { TrackorderdialogComponent } from './trackorderdialog/trackorderdialog.component';
import { TourComponent } from './tour/tour.component';
import { ToastrModule, ToastContainerModule  } from 'ngx-toastr';
import { NgxGalleryModule } from 'ngx-gallery';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordDailogComponent } from './password-dailog/password-dailog.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { YoutubeDialogComponent } from './youtube-dialog/youtube-dialog.component';
import { MovieDailogComponent } from './movie-dailog/movie-dailog.component';
import { TourPlaceComponent } from './tour-place/tour-place.component';
import { LoaderComponent } from './loader/loader.component';
import { WindowRef } from '../windowRef';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { StatusdialogComponent } from './statusdialog/statusdialog.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SessionexpiredComponent } from './sessionexpired/sessionexpired.component';
import { OwlRadioModule } from 'owl-ng';
import { FoodListComponent } from './food-list/food-list.component';
import { ResturantComponent } from './resturant/resturant.component';
import { GroceryStoresComponent } from './grocery-stores/grocery-stores.component';
import { GroceryListComponent } from './grocery-list/grocery-list.component';
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { AngularFireModule } from "@angular/fire";
// import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
// import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS  } from "@angular/fire/remote-config";
// import { environment } from '../environments/environment';
import { CountdownModule } from 'ngx-countdown';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { ComputerListComponent } from './computer-list/computer-list.component';
import { ComputerStoresComponent  } from './computer-stores/computer-stores.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FoodComponent,
    GroceryComponent,
    MedicineComponent,
    MovieComponent,
    TravelComponent,
    FooterComponent,
    HeaderComponent,
    SigninComponent,
    SignupComponent,
    TourDetailsComponent,
    MovieDetailsComponent,
    BasketComponent,
    ProfileComponent,
    AddressdailogComponent,
    ConfirmationDialogComponent,
    OrdersComponent,
    ContactComponent,
    StepperdailogComponent,
    TrackorderdialogComponent,
    TourComponent,
    PageNotFoundComponent,
    PasswordDailogComponent,
    YoutubeDialogComponent,
    MovieDailogComponent,
    TourPlaceComponent,
    LoaderComponent,
    PrivacyComponent,
    TermsComponent,
    StatusdialogComponent,
    AboutusComponent,
    SessionexpiredComponent,
    FoodListComponent,
    ResturantComponent,
    GroceryStoresComponent,
    GroceryListComponent,
    ComputerListComponent,
    ComputerStoresComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCIKY5rM14UAkUtLc9h6IuSZRAYRYJUHWs',
      apiKey: 'AIzaSyAPg_poTJuv2V_VRzWpzhNEJElpRkkC8q8'
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ToastContainerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatToolbarModule, 
    MatButtonModule, 
    MatSidenavModule, 
    MatIconModule, 
    MatListModule, 
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatTooltipModule, 
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    HttpClientModule,
    MatFormFieldModule,
    MatMenuModule,
    MatStepperModule,
    MatSnackBarModule,
    NgxGalleryModule,
    NgwWowModule,
    NgxYoutubePlayerModule.forRoot(),
    SliderModule,
    OwlModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    OwlFormFieldModule,
    OwlInputModule,
    OwlSelectModule,
    OwlRadioModule,
    // ScrollingModule,
    InfiniteScrollModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    // AngularFireRemoteConfigModule,
    CountdownModule,
    CountdownTimerModule.forRoot()
  ],
  entryComponents:[YoutubeDialogComponent, AddressdailogComponent, ConfirmationDialogComponent, StepperdailogComponent, TrackorderdialogComponent, PasswordDailogComponent, MovieDailogComponent, StatusdialogComponent, SessionexpiredComponent],
  providers: [
    WindowRef,
    // { provide: DEFAULTS, useValue: { BASE_URL: 'http://chn.adyog.com:45870/' } },
    // {
    //   provide: SETTINGS,
    //   useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    // },
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

