import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NagercoilService } from '../_services/nagercoil.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WindowRef } from '../../windowRef';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-stepperdailog',
	templateUrl: './stepperdailog.component.html',
	styleUrls: ['./stepperdailog.component.css']
})

export class StepperdailogComponent implements OnInit {
	public selected = '';
	public firstFormGroup: FormGroup;
	public secondFormGroup: FormGroup;
	public thirdFormGroup: FormGroup;
	public enableForm: boolean = false;
	public addressForm: FormGroup;
	public lat: any;
	public lng: any;
	public mode = 'form';
	public errorMsg: any;
	public user: object;
	public addressData: object[];
	public isPlaced = false;
	public isSuccess: any;
	public isLoaded: boolean = false;
	public loader: boolean = false;
	public rzp1: any;
	public closeWithCallBack: boolean = false;
	public isCancelled: boolean = false;
	public categoryData: any;
	public paymentOptions: any;
	public catagoryId: any;
	public deliveryChargeDetails: any;
	public vouchers: any;
	public isApplyCoupon: boolean = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data, private toastr: ToastrService, private winRef: WindowRef, private _formBuilder: FormBuilder, private fb: FormBuilder, private Service: NagercoilService, public dialogRef: MatDialogRef<StepperdailogComponent>) {
		this.lat = 8.1833,
			this.lng = 77.41
		if (navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				this.lng = +pos.coords.longitude;
				this.lat = +pos.coords.latitude;
			});
		}
	}

	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (this.data && this.data.length) {
			if (this.data[0]['CategoryId'] == 1) {
				this.catagoryId = '1';
			}
			else if (this.data[0]['CategoryId'] == 2) {
				this.catagoryId = '2';
			} else if (this.data[0]['CategoryId'] == 7) {
				this.catagoryId = '7';
			}

			if (this.catagoryId) {
				this.getCartDetails();
				if (this.catagoryId == 1) {
					this.getVouchers();
				}
			}
		}

		this.firstFormGroup = this._formBuilder.group({
			firstCtrl: ['', Validators.required]
		});

		this.secondFormGroup = this._formBuilder.group({
			secondCtrl: ['', Validators.required],
			coupon: [''],
			payment: ['', Validators.required],
		});

		this.thirdFormGroup = this._formBuilder.group({
			thirdCtrl: ['', Validators.required]
		});

		this.addressForm = this.fb.group({
			name: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: [null, Validators.compose([Validators.required])],
			country: ["India", Validators.compose([Validators.required])],
			add1: [null, Validators.compose([Validators.required])],
			add2: [null, ''],
			city: ["Nagercoil", Validators.compose([Validators.required])],
			state: ["Tamil Nadu", Validators.compose([Validators.required])],
			zip: [null, Validators.compose([Validators.required])],
			latLng: [null, ''],
			isDefault: [null, '']
		});
		this.getAddress();
		this.getCategory();
	}

	markerDragEnd($event: MouseEvent) {
		this.lat = $event['coords'].lat;
		this.lng = $event['coords'].lng;
	}

	saveLocation() {
		var concat = this.lat.toFixed(2).toString().concat(' , ');
		var latLng = concat.concat(this.lng.toFixed(2).toString());
		this.addressForm.controls['latLng'].setValue(latLng);
		this.mode = 'form';
	}

	createAddress() {
		if (this.addressForm.invalid) {
			return;
		}
		this.loader = true;
		let token = this.user['token'];
		let params = {
			"name": this.addressForm.value.name.trim(),
			"email": this.addressForm.value.email.trim(),
			"phone": parseInt(this.addressForm.value.phone),
			"add1": this.addressForm.value.add1.trim(),
			"city": this.addressForm.value.city.trim(),
			"state": this.addressForm.value.state.trim(),
			"country": this.addressForm.value.country.trim(),
			"zip": this.addressForm.value.zip.toString(),
			"is_primary": this.addressForm.value.isDefault ? 1 : 0
		}
		if (this.addressForm.value.add2) {
			params['add2'] = this.addressForm.value.add2.trim()
		}
		if (this.addressForm.value.latLng) {
			if (this.lat && this.lng) {
				params['lat'] = this.lat
				params['long'] = this.lng
			}
		}

		let route = 'address'
		this.Service.post(route, token, params).then(response => {
			this.loader = false;
			this.isLoaded = true;
			if (response.status) {
				this.enableForm = false;
				this.addressForm.reset();
				this.getAddress();
			} else {
				this.errorMsg = response.messages;
			}
		});
	}

	getAddress() {
		let token = this.user['token'];
		let route = 'address';
		this.Service.get(route, token).then(response => {
			if (response.data) {
				this.addressData = response.data;
				for (let i = 0; i < this.addressData.length; i++) {
					if (this.addressData[i]['is_primary'] == 1) {
						this.selected = String(this.addressData[i]['addressId']);
					}
					else {
						this.selected = String(this.addressData[0]['addressId']);
					}
				}
				this.isLoaded = true;
			}
		});
	}

	getCategory() {
		let token = this.user['token'];
		let route = 'category';
		this.Service.get(route, token).then(response => {
			if (response.data) {
				this.categoryData = response.data;
				this.paymentOptions = [];
				for (let i = 0; i < this.data.length; i++) {
					let Index = this.categoryData.map(function (x) {
						return x['id'];
					}).indexOf(this.data[i]['CategoryId']);
					if (Index != -1) {
						this.paymentOptions = this.categoryData[Index].payments;
					}
				}
				if (this.paymentOptions && this.paymentOptions.length) {
					this.secondFormGroup.patchValue({
						payment: this.paymentOptions[0].code
					})
				}
			}
		});
	}

	getCartDetails() {
		let token = this.user['token'];
		let route = 'v2/cart/details';
		let params = {
			category_id: this.catagoryId
		};
		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				this.deliveryChargeDetails = response.data;
			}
		});
	}

	getVouchers() {
		let token = this.user['token'];
		let route = 'restaurants/voucher';
		this.Service.get(route, token).then(response => {
			if (response) {
				this.vouchers = response;
			}
		});
	}

	applyCoupon(code: any) {
		let token = this.user['token'];
		let route = 'apply/voucher';
		let params = {
			category_id: this.catagoryId,
			coupon: "add"
		}
		if (code) {
			params['coupon_code'] = code;
		} else {
			if (this.secondFormGroup.value.coupon) {
				params['coupon_code'] = this.secondFormGroup.value.coupon.trim().toUpperCase();
			} else {
				return;
			}
		}

		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				this.toastr.success('Coupon applied successfully', 'Success');
				this.isApplyCoupon = false;
				this.getCartDetails();
				this.secondFormGroup.patchValue({
					coupon: ''
				});
			} else {
				this.toastr.error(response.message);
				this.secondFormGroup.patchValue({
					coupon: ''
				});
			}
		});
	}

	removeCoupon(code: any) {
		let token = this.user['token'];
		let route = 'apply/voucher';
		let params = {
			coupon_code: code,
			category_id: this.catagoryId,
			coupon: "remove"
		};

		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				this.toastr.success('Coupon removed successfully', 'Success');
				this.isApplyCoupon = false;
				this.getCartDetails();
			} else {
				this.toastr.error(response.message);
			}
		});

	}

	toggle() {
		this.isApplyCoupon = !this.isApplyCoupon;
		this.secondFormGroup.patchValue({
			coupon: ''
		});
	}

	placeOrder() {
		this.loader = true;
		var product_ids = [];
		for (let i = 0; i < this.data.length; i++) {
			product_ids.push(this.data[i].ProductId);
		}
		let token = this.user['token'];
		let route = 'v2/order';
		let params = {
			"product_ids": product_ids,
			"category_id": this.data[0].CategoryId,
			'address_id': parseInt(this.selected),
			// 'payment_type': this.data[0].CategoryId <= 3 ? 'cash_on_delivery': 'razorpay'
			'payment_type': this.secondFormGroup.value.payment
		}
		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				this.closeWithCallBack = true;
				// this.data[0].CategoryId > 3)
				if (this.secondFormGroup.value.payment == 'razorpay') {
					let token = this.user['token'];
					let route = 'payment/create/order';
					let params = {
						'order_id': response.order_id
					}
					var self = this;
					this.Service.post(route, token, params).then(paymentObj => {
						if (paymentObj.success) {
							var options = {
								"key" : "rzp_live_LzLNSHuVgP2GOm",
								// "key": "rzp_test_XCu5GHd0psAXAs",
								"amount": paymentObj.data.amount,
								"currency": paymentObj.data.currency,
								"name": "Nagercoil",
								"description": "Payment",
								"image": "https://s3.amazonaws.com/rzp-mobile/images/rzp.png",
								"order_id": paymentObj.data.order_id,
								"handler": function (handlerResp: any) {
									if (handlerResp.razorpay_order_id && handlerResp.razorpay_payment_id && handlerResp.razorpay_signature) {
										let route = 'payment/verify/signature'
										let token = self.user['token']
										let params = {
											razorpay_order_id: handlerResp.razorpay_order_id,
											razorpay_payment_id: handlerResp.razorpay_payment_id,
											razorpay_signature: handlerResp.razorpay_signature
										}
										self.loader = true;
										self.Service.post(route, token, params).then(sigObj => {
											if (sigObj.success) {
												self.isSuccess = true;
												self.isPlaced = true;
											} else {
												self.isSuccess = false;
												self.isPlaced = true;
											}
										});
									}
								},
								"prefill": {
									"name": this.user['name'],
									"email": this.user['email'],
									"contact": this.user['phone']
								},
								"notes": paymentObj.data.notes,
								"theme": {
									"color": "#0066c0"
								},
								"modal": {
									"ondismiss": function () {
										self.loader = true;
										// let route = "payment/cancel/order";
										let route = "payment/cancelled";
										let params = {
											"razorpay_order_id": paymentObj.data.order_id
										}
										self.Service.post(route, self.user['token'], params).then(resp => {
											if (resp.success) {
												self.loader = false;
												self.closeWithCallBack = false;
												// self.dialogRef.close(true);
												self.toastr.error('Payment has been cancelled');
											} else {
												self.loader = false;
												self.closeWithCallBack = false;
												// self.dialogRef.close(true);
												self.toastr.error('Please try aftersome times', 'Something went wrong!');
											}
										})
									}
								}
							};
							this.rzp1 = new this.winRef.nativeWindow.Razorpay(options);
							this.rzp1.open();
							this.loader = false;
						}

					});
				} else {
					this.isSuccess = true;
					this.isPlaced = true;
				}

			} else {
				this.isSuccess = false;
				this.isPlaced = true;
			}
		});
	}

	get payment() {
		return this.secondFormGroup.get('payment').value;
	}

}
