import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { StepperdailogComponent } from '../stepperdailog/stepperdailog.component';
import { NagercoilService } from "../_services/nagercoil.service";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';


@Component({
	selector: 'app-basket',
	templateUrl: './basket.component.html',
	styleUrls: ['./basket.component.css']
})

export class BasketComponent implements OnInit {
	images: any[] = [
		'../../assets/images/ind_img/food.jpg',
		'../../assets/images/ind_img/grocerys.jpg']

	public user: object;
	public allBasket: object[];
	public foodBasket: object[];
	public groceryBasket: object[];
	public medicineBasket: object[];
	public movieBasket: object[];
	public travelBasket: object[];
	public tourBasket: object[];
	public foodSavedBasket: object[];
	public grocerySavedBasket: object[];
	public medicineSavedBasket: object[];
	public movieSavedBasket: object[];
	public travelSavedBasket: object[];
	public tourSavedBasket: object[];
	public computerBasket: object[];
	public computerSavedBasket: object[];
	public galleryOptions: NgxGalleryOptions[];
	public galleryImages: NgxGalleryImage[];
	public loader: boolean = false;
	public index;

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private toastr: ToastrService, private route: ActivatedRoute, public dialog: MatDialog, private router: Router, private Service: NagercoilService) { }

	ngOnInit() {
		this.loader = true;
		this.renderer.addClass(this.document.body, 'bdovflw_hi');
		this.user = JSON.parse(localStorage.getItem('userCredential'));
		if (!this.user) {
			this.router.navigate(['signin']);
		} else {
			var self = this;
			this.getBasket(function (response: any) {
				if (response) {
					self.loader = false;
				}
			});
			this.galleryOptions = [
				{
					width: '250px',
					height: '200px',
					thumbnailsColumns: 4,
					preview: true,
					thumbnails: false,
					previewCloseOnClick: true,
					previewCloseOnEsc: true,
					previewKeyboardNavigation: true,
					imageAutoPlayPauseOnHover: true
				}
			];
		}
	}

	confirmAvailability(data: any, catagoryId: any): void {
		let token = this.user['token'];
		let route = 'v2/cart/details';
		let params = {
			category_id: catagoryId
		};
		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				const dialogRef = this.dialog.open(StepperdailogComponent, {
					width: '950px',
					disableClose: true,
					data: data
				});

				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.loader = true;
						var self = this;
						this.getBasket(function (response: any) {
							if (response) {
								self.loader = false;
							}
						});
					}
				});
			} else {
				this.toastr.error('Please clear the cart and try again.', 'One of your cart items is invalid.');
			}
		});
	}

	openDialog(data: any): void {
		const dialogRef = this.dialog.open(StepperdailogComponent, {
			width: '950px',
			disableClose: true,
			data: data
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loader = true;
				var self = this;
				this.getBasket(function (response: any) {
					if (response) {
						self.loader = false;
					}
				});
			}
		});
	}

	openPaymentDialog(data: any) {
		data = [data]
		const dialogRef = this.dialog.open(StepperdailogComponent, {
			width: '950px',
			disableClose: true,
			data: data
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loader = true;
				var self = this;
				this.getBasket(function (response: any) {
					if (response) {
						self.loader = false;
					}
				});
			}
		});
	}

	getBasket(callback: any) {
		if (this.user) {
			let route = 'v2/product';
			this.Service.get(route, this.user['token']).then(response => {

				this.renderer.removeClass(this.document.body, 'bdovflw_hi');
				if (response.data) {
					this.allBasket = response.data;
					let basketCount = response.data.filter(function (product: any) {
						return product['ForLater'] == 0
					});
					this.Service.changeCount(basketCount.length);
					this.foodBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 1 && product['ForLater'] == 0
					});

					this.foodSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 1 && product['ForLater'] == 1
					});
					for (let i = 0; i < this.foodBasket.length; i++) {
						this.foodBasket[i]['isAdded'] = false;
						var tempArr = []
						if (this.foodBasket[i]['Items'][0]['Images'] && this.foodBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.foodBasket[i]['Items'][0]['Images'].length; j++) {

								tempArr.push({
									'medium': this.foodBasket[i]['Items'][0]['Images'][j],
									'big': this.foodBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.foodBasket[i]['Items'][0]['Images'].length - 1) {
									this.foodBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.foodBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.foodSavedBasket.length; i++) {
						var tempArr = []
						if (this.foodSavedBasket[i]['Items'][0]['Images'] && this.foodSavedBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.foodSavedBasket[i]['Items'][0]['Images'].length; j++) {

								tempArr.push({
									'medium': this.foodSavedBasket[i]['Items'][0]['Images'][j],
									'big': this.foodSavedBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.foodSavedBasket[i]['Items'][0]['Images'].length - 1) {
									this.foodSavedBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.foodSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					this.groceryBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 2 && product['ForLater'] == 0
					});

					this.grocerySavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 2 && product['ForLater'] == 1
					});

					for (let i = 0; i < this.groceryBasket.length; i++) {
						this.groceryBasket[i]['isAdded'] = false;
						var tempArr = []
						if (this.groceryBasket[i]['Items'][0]['Images'] && this.groceryBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.groceryBasket[i]['Items'][0]['Images'].length; j++) {
								tempArr.push({
									'medium': this.groceryBasket[i]['Items'][0]['Images'][j],
									'big': this.groceryBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.groceryBasket[i]['Items'][0]['Images'].length - 1) {
									this.groceryBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.groceryBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.grocerySavedBasket.length; i++) {
						var tempArr = []
						if (this.grocerySavedBasket[i]['Items'][0]['Images'] && this.grocerySavedBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.grocerySavedBasket[i]['Items'][0]['Images'].length; j++) {

								tempArr.push({
									'medium': this.grocerySavedBasket[i]['Items'][0]['Images'][j],
									'big': this.grocerySavedBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.grocerySavedBasket[i]['Items'][0]['Images'].length - 1) {
									this.grocerySavedBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.grocerySavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})


					this.medicineBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 3 && product['ForLater'] == 0
					});
					this.medicineSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 3 && product['ForLater'] == 1
					});

					for (let i = 0; i < this.medicineBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.medicineBasket[i]['Images'].length; j++) {

							tempArr.push({
								'medium': this.medicineBasket[i]['Images'][j].ImageUrl,
								'big': this.medicineBasket[i]['Images'][j].ImageUrl
							})
							if (j == this.medicineBasket[i]['Images'].length - 1) {
								this.medicineBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.medicineBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.medicineSavedBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.medicineSavedBasket[i]['Images'].length; j++) {

							tempArr.push({
								'medium': this.medicineSavedBasket[i]['Images'][j].ImageUrl,
								'big': this.medicineSavedBasket[i]['Images'][j].ImageUrl
							})
							if (j == this.medicineSavedBasket[i]['Images'].length - 1) {
								this.medicineSavedBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.medicineSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					this.movieBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 4 && product['ForLater'] == 0
					});

					this.movieSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 4 && product['ForLater'] == 1
					});
					for (let i = 0; i < this.movieBasket.length; i++) {
						if (this.movieBasket[i]['Items']) {
							var tempArr = [];
							for (let j = 0; j < this.movieBasket[i]['Items']['TicketDetails']['MovieImages'].length; j++) {
								if (this.movieBasket[i]['Items']['TicketDetails']['MovieImages'][j].ImageType == 'card') {
									tempArr.push({
										'medium': this.movieBasket[i]['Items']['TicketDetails']['MovieImages'][j].ImageUrl,
										'big': this.movieBasket[i]['Items']['TicketDetails']['MovieImages'][j].ImageUrl,
									});
								}
							}
							this.movieBasket[i]['Gallery'] = tempArr;
						}
					}
					this.movieBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.movieSavedBasket.length; i++) {
						if (this.movieSavedBasket[i]['Items']) {
							var tempArr = [];
							tempArr.push({
								'medium': this.movieSavedBasket[i]['Items']['TicketDetails']['MovieImages'][1].ImageUrl,
								'big': this.movieSavedBasket[i]['Items']['TicketDetails']['MovieImages'][1].ImageUrl,
							})
							this.movieSavedBasket[i]['Gallery'] = tempArr;
						}
					}
					this.movieSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})
					this.travelBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 5 && product['ForLater'] == 0
					});

					this.travelSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 5 && product['ForLater'] == 1
					});

					for (let i = 0; i < this.travelBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.travelBasket[i]['Items']['carDetails']['Images'].length; j++) {
							tempArr.push({
								'medium': this.travelBasket[i]['Items']['carDetails']['Images'][j],
								'big': this.travelBasket[i]['Items']['carDetails']['Images'][j]
							})
							if (j == this.travelBasket[i]['Items']['carDetails']['Images'].length - 1) {
								this.travelBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.travelBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.travelSavedBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.travelSavedBasket[i]['Items']['carDetails']['Images'].length; j++) {
							tempArr.push({
								'medium': this.travelSavedBasket[i]['Items']['carDetails']['Images'][j],
								'big': this.travelSavedBasket[i]['Items']['carDetails']['Images'][j]
							})
							if (j == this.travelSavedBasket[i]['Items']['carDetails']['Images'].length - 1) {
								this.travelSavedBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.travelSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					this.tourBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 6 && product['ForLater'] == 0
					});

					this.tourSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 6 && product['ForLater'] == 1
					});

					for (let i = 0; i < this.tourBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.tourBasket[i]['Items']['packageDetails']['Images'].length; j++) {
							tempArr.push({
								'medium': this.tourBasket[i]['Items']['packageDetails']['Images'][j],
								'big': this.tourBasket[i]['Items']['packageDetails']['Images'][j]
							})
							if (j == this.tourBasket[i]['Items']['packageDetails']['Images'].length - 1) {
								this.tourBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.tourBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})


					for (let i = 0; i < this.tourSavedBasket.length; i++) {
						var tempArr = []
						for (let j = 0; j < this.tourSavedBasket[i]['Items']['packageDetails']['Images'].length; j++) {
							tempArr.push({
								'medium': this.tourSavedBasket[i]['Items']['packageDetails']['Images'][j],
								'big': this.tourSavedBasket[i]['Items']['packageDetails']['Images'][j]
							})
							if (j == this.tourSavedBasket[i]['Items']['packageDetails']['Images'].length - 1) {
								this.tourSavedBasket[i]['Gallery'] = tempArr
							}
						}
					}
					this.tourSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					this.computerBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 7 && product['ForLater'] == 0
					});

					this.computerSavedBasket = this.allBasket.filter(function (product) {
						return product['CategoryId'] == 7 && product['ForLater'] == 1
					});

					for (let i = 0; i < this.computerBasket.length; i++) {
						this.computerBasket[i]['isAdded'] = false;
						var tempArr = []
						if (this.computerBasket[i]['Items'][0]['Images'] && this.computerBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.computerBasket[i]['Items'][0]['Images'].length; j++) {
								tempArr.push({
									'medium': this.computerBasket[i]['Items'][0]['Images'][j],
									'big': this.computerBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.computerBasket[i]['Items'][0]['Images'].length - 1) {
									this.computerBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.computerBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})

					for (let i = 0; i < this.computerSavedBasket.length; i++) {
						var tempArr = []
						if (this.computerSavedBasket[i]['Items'][0]['Images'] && this.computerSavedBasket[i]['Items'][0]['Images'].length) {
							for (let j = 0; j < this.computerSavedBasket[i]['Items'][0]['Images'].length; j++) {

								tempArr.push({
									'medium': this.computerSavedBasket[i]['Items'][0]['Images'][j],
									'big': this.computerSavedBasket[i]['Items'][0]['Images'][j]
								})
								if (j == this.computerSavedBasket[i]['Items'][0]['Images'].length - 1) {
									this.computerSavedBasket[i]['Gallery'] = tempArr
								}
							}
						}
					}
					this.computerSavedBasket.sort((a, b) => {
						return (b['CreatedAt'] - a['CreatedAt']);
					})
					
					callback(true)
				}
			});
		}
	}

	removeBasket(basketObj: any) {
		var obj = {
			dataObj: basketObj,
			component: 'basketComponent'
		}
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '500px',
			data: obj
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loader = true;
				var self = this
				this.getBasket(function (response) {
					if (response) {
						self.loader = false;
					}
				});
			}
		})
	}

	saveForLater(productId: any, forlater: any) {
		this.loader = true;
		let token = this.user['token']
		let route = 'forlater'
		let params = {
			"product_id": productId,
			"for_later": !forlater
		}
		this.Service.post(route, token, params).then(response => {
			if (response.status) {
				var self = this;
				this.getBasket(function (response: any) {
					if (response) {

						let temp = !forlater ? 'Saved for Later' : 'Added to basket';
						self.toastr.success(temp, 'Success');
						self.loader = false;

					}
				});

			} else {
				this.toastr.error('Please try after sometime.', 'Something went wrong!');
			}
		});
	}

	getImages(data: any) {
		for (let i = 0; i < data.Images.length; i++) {
			var tempArr = []
			tempArr.push({
				'medium': data.Images[i].ImageUrl,
				'big': data.Images[i].ImageUrl
			})
			if (i == data.Images.length - 1) {
				return tempArr
			}
		}
	}

	incrementQty(item) {
		if (item.CategoryId == 1) {
			for (let i = 0; i < this.foodBasket.length; i++) {
				if (item.ProductId == this.foodBasket[i]['ProductId']) {
					this.foodBasket[i]['isAdded'] = true;
				} else {
					this.foodBasket[i]['isAdded'] = false;
				}
			}
		} else if (item.CategoryId == 2) {
			for (let i = 0; i < this.groceryBasket.length; i++) {
				if (item.ProductId == this.groceryBasket[i]['ProductId']) {
					this.groceryBasket[i]['isAdded'] = true;
				} else {
					this.groceryBasket[i]['isAdded'] = false;
				}
			}
		} else if (item.CategoryId == 7) {
			for (let i = 0; i < this.computerBasket.length; i++) {
				if (item.ProductId == this.computerBasket[i]['ProductId']) {
					this.computerBasket[i]['isAdded'] = true;
				} else {
					this.computerBasket[i]['isAdded'] = false;
				}
			}
		}

		if (item.Items && item.Items.length) {
			let route = 'v2/product/' + item.ProductId + '?_method=PUT';
			let params = {
				shop_name: item.Shop,
				items: [{
					id: item.Items[0].ItemId,
					itemOrder: item.Items[0].ItemOrder,
					itemName: item.Items[0].Item,
					quantity: (parseInt(item.Items[0].Quantity) + 1).toString(),
					action: "update",
				}],
			}

			if (item.CategoryId == 1) {
				params['category'] = '1';
				for (let i = 0; i < params['items'].length; i++) {
					params['items'][i]['category'] = 1
				}
			} else if (item.CategoryId == 2) {
				params['category'] = '2';
				for (let i = 0; i < params['items'].length; i++) {
					params['items'][i]['category'] = 2
				}
			} else if (item.CategoryId == 7) {
				params['category'] = '7';
				for (let i = 0; i < params['items'].length; i++) {
					params['items'][i]['category'] = 7
				}
			}
			this.Service.post(route, this.user['token'], params).then(response => {
				if (response.status) {
					this.getBasket(function (response: any) { });
				} else {
					for (let i = 0; i < this.foodBasket.length; i++) {
						this.foodBasket[i]['isAdded'] = false;
					}
					for (let i = 0; i < this.groceryBasket.length; i++) {
						this.groceryBasket[i]['isAdded'] = false;
					}
					this.toastr.error('Please try after sometime.', 'Something went wrong!');
				}
			});
		}
	}

	decrementQty(item) {
		if (item.CategoryId == 1) {
			for (let i = 0; i < this.foodBasket.length; i++) {
				if (item.ProductId == this.foodBasket[i]['ProductId']) {
					this.foodBasket[i]['isAdded'] = true;
				} else {
					this.foodBasket[i]['isAdded'] = false;
				}
			}
		} else if (item.CategoryId == 2) {
			for (let i = 0; i < this.groceryBasket.length; i++) {
				if (item.ProductId == this.groceryBasket[i]['ProductId']) {
					this.groceryBasket[i]['isAdded'] = true;
				} else {
					this.groceryBasket[i]['isAdded'] = false;
				}
			}
		} else if (item.CategoryId == 7) {
			for (let i = 0; i < this.computerBasket.length; i++) {
				if (item.ProductId == this.computerBasket[i]['ProductId']) {
					this.computerBasket[i]['isAdded'] = true;
				} else {
					this.computerBasket[i]['isAdded'] = false;
				}
			}
		}

		if (item.Items && item.Items.length) {
			if (item.Items[0].Quantity == '1') {
				let route = 'v2/product/' + item.ProductId;
				this.Service.delete(route, this.user['token']).then(response => {
					if (response.status) {
						this.getBasket(function (response: any) { });
					} else {
						for (let i = 0; i < this.foodBasket.length; i++) {
							this.foodBasket[i]['isAdded'] = false;
						}
						for (let i = 0; i < this.groceryBasket.length; i++) {
							this.groceryBasket[i]['isAdded'] = false;
						}
						this.toastr.error('Please try after sometime.', 'Something went wrong!');
					}
				});
			} else if (item.Items[0].Quantity > 1) {
				let route = 'v2/product/' + item.ProductId + '?_method=PUT';
				let params = {
					shop_name: item.Shop,
					items: [{
						id: item.Items[0].ItemId,
						itemOrder: item.Items[0].ItemOrder,
						itemName: item.Items[0].Item,
						quantity: (parseInt(item.Items[0].Quantity) - 1).toString(),
						action: "update",
					}],
				}

				if (item.CategoryId == 1) {
					params['category'] = '1';
					for (let i = 0; i < params['items'].length; i++) {
						params['items'][i]['category'] = 1
					}
				} else if (item.CategoryId == 2) {
					params['category'] = '2';
					for (let i = 0; i < params['items'].length; i++) {
						params['items'][i]['category'] = 2
					}
				} else if (item.CategoryId == 7) {
					params['category'] = '7';
					for (let i = 0; i < params['items'].length; i++) {
						params['items'][i]['category'] = 7
					}
				}

				this.Service.post(route, this.user['token'], params).then(response => {
					if (response.status) {
						this.getBasket(function (response: any) { });
					} else {
						for (let i = 0; i < this.foodBasket.length; i++) {
							this.foodBasket[i]['isAdded'] = false;
						}
						for (let i = 0; i < this.groceryBasket.length; i++) {
							this.groceryBasket[i]['isAdded'] = false;
						}
						this.toastr.error('Please try after sometime.', 'Something went wrong!');
					}
				});
			}

		}
	}

}
